import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { PALETTE_PIECES, CHESS_PIECES, ASSETS } from '../../constants';
import { setBrush } from '../../model/actions';
import './index.css';

class PositionPalette extends React.Component {
  render() {
    const data = [
      { name: null, src: ASSETS.POINTER },
      { name: 'x', src: ASSETS.X },
    ].concat(PALETTE_PIECES.map(name => {
      return { name, src: CHESS_PIECES[this.props.chessSet][name] }
    })); 

    const content = data.map(({name, src}, key) => {
    const className = this.props.brush === name ? 'selected' : '';

      return (
        <img className={className}
          src={src} 
          key={key}
          onClick={() => this.props.onSelect(name)} 
        />
      )
    });

    return (
      <div className={classnames("PositionPalette", {disabled: this.props.disabled})}>
        {content}
      </div>
    );
  }
}

const mapStateToProps = state => (
  { 
    chessSet: state.settings.chessSet,
  }
);

export default connect(mapStateToProps)(PositionPalette);