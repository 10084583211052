import React from 'react';
import classnames from 'classnames';
import './index.css';

const SVG = (
  <svg viewBox="0 0 100 80" width="40" height="40">
    <rect width="60" height="10" rx="8"></rect>
    <rect y="20" width="60" height="10" rx="8"></rect>
    <rect y="40" width="60" height="10" rx="8"></rect>
  </svg>
);

export default class Hamburger extends React.Component {
  render() {
    const className = classnames("Hamburger", this.props.className);
    return (
      <div className={className} { ...this.props } className={className}>
        {SVG}
      </div>
    );
  }
}