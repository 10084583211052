import React from 'react';
import classnames from 'classnames';

import Chess from '../../../model/data/Chess';
import ChessBoard from '../../ChessBoard';
import FenString from '../../FenString';
import PositionPalette from '../../PositionPalette';
import './index.css';

const PositionOptions = (props) => (
  <div className={classnames("PositionOptions", {disabled: props.disabled})}>
    <div className="Button Clear" onClick={props.clear}>Clear</div>
    <div className="Button Reset" onClick={props.reset}>Reset</div>
    <div className="0-0 toggle">
      <label>White O-O</label>
      <input 
        type="checkbox"
        checked={props["O-O"]}
        onChange={props.toggleWhiteKingside}
      />
    </div>
    <div className="O-O-O toggle">
      <label>White O-O-O</label>
      <input 
        type="checkbox" 
        checked={props["O-O-O"]}
        onChange={props.toggleWhiteQueenside}
      />
    </div>
    <div className="0-0 toggle">
      <label>Black O-O</label>
      <input 
        type="checkbox"
        checked={props["O-O"]}
        onChange={props.toggleBlackKingside}
      />
    </div>
    <div className="O-O-O toggle">
      <label>Black O-O-O</label>
      <input 
        type="checkbox" 
        checked={props["O-O-O"]}
        onChange={props.toggleBlackQueenside}
      />
    </div>
    <div className="SideToMove">
      <label>White to move</label>
      <input 
        type="checkbox" 
        checked={props.sideToMove === 'w'}
        onChange={() => props.setSideToMove('w')}
      />
    </div>
    <div className="SideToMove">
      <label>Black to move</label>
      <input 
        type="checkbox" 
        checked={props.sideToMove === 'b'}
        onChange={() => props.setSideToMove('b')}
      />
    </div>
  </div>
);

export default class PositionSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fen: new Chess().fen(),
      brush: null,
    };
  }

  setFen = (fen) => {
    this.setState({ fen, brush: null });
  }

  handleClear = () => {
    const chess = new Chess();
    chess.clear();
    this.setFen(chess.fen());
  }

  handleReset = () => {
    const chess = new Chess();
    this.setFen(chess.fen());
  }

  handleMove = (move) => {
    const chess = new Chess(this.state.fen);
    const piece = chess.get(move.from);
    chess.remove(move.from);
    chess.put(piece, move.to);
    this.setState({ fen: chess.fen() });
  }

  handleSquare = (square) => {
    if (this.state.brush === 'x') {
      const chess = new Chess(this.state.fen);
      chess.remove(square);
      this.setState({
        fen: chess.fen(),
      });
      return;
    }

    const color = this.state.brush[0];
    const type = this.state.brush[1];
    const chess = new Chess(this.state.fen);
    chess.put({ type, color }, square);
    this.setState({
      fen: chess.fen(),
    });
  } 

  setBrush = (brush) => {
    this.setState({
      brush,
    });
  }

  getSideToMove = () => {
    const segs = this.state.fen.split(' ');
    return segs[1];
  }

  setSideToMove = (side) => {
    const segs = this.getFen().split(' ');
    if (segs[1] === side) { return; }
    segs[1] = side;
    const fen = segs.join(' ');
    this.setFen(fen);
  }

  render() {
    return (
      <div className="PositionSetupPage">
        <div className="LeftSection">
          <ChessBoard 
            onDragStart={() => true}
            fen={this.state.fen}
            sendMove={this.handleMove}
            sendSquare={this.handleSquare}
            brush={this.state.brush}
            draggable
            allowInvalidMoves
          />
          <FenString 
            fen={this.state.fen}
            onChange={this.handleFenStringChange}
          />
        </div>
        <div className="RightSection">
          <PositionPalette
            brush={this.state.brush}
            onSelect={this.setBrush}
          />
          <PositionOptions
            toggleWhiteKingside={this.toggleWhiteKingside}
            toggleWhiteQueenside={this.toggleWhiteQueenside}
            toggleBlackKingside={this.toggleBlackKingside}
            toggleBlackQueenside={this.toggleBlackQueenside}
            setSideToMove={this.setSideToMove}
            sideToMove={this.getSideToMove()}
            clear={this.handleClear}
            reset={this.handleReset}
          />
        </div>
      </div>
    );
  }
}