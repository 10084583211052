import { ENGINE } from './constants';

class Engine {
  constructor() {
    this.state = ENGINE.NOT_LOADED;
    this.listener = null;
    this.worker = new Worker('/stockfish.js');
    this.worker.onmessage = (message) => this._handleMessage(message);
    this.worker.postMessage('uci');
  }

  sendCommand(command) {
    this.worker.postMessage(command);
  }

  setListener(listener) {
    this.listener = listener;
  }

  _handleMessage(message) {
    if (typeof message === 'object') {
      message = message.data;
    }
    if (message === 'uciok') {
      this.state = ENGINE.LOADED;
    }
    this.listener(message);
  }

  setPosition(fen) {
    this.sendCommand(`position fen ${fen}`);
  }

  setMultiPV(multipv) {
    this.sendCommand(`setoption name MultiPV value ${multipv}`);
  }

  analyse(fen, depth=12, branches=2) {
    this.setMultiPV(branches);
    this.setPosition(fen);
    this.sendCommand(`go depth ${depth}`);
    return new Promise((resolve) => {
      const results = [];

      this.setListener((message) => {
        const segs = message.split(' ');

        if (segs[0] === 'bestmove') {
          resolve(results);
        }

        // otherwise if it's not a multipv update ignore
        const data = parseMultipvInfo(message);
        if (!data) { return; }
        
        const index = data.multipv-1;
        results[index] = data;
      });
    });
  }
}

export const engines = {};

export const resetEngine = (key="default") => {
  destroyEngine(key);
  return getEngine(key);
}

export const getEngine = (key="default") => {
  if (engines[key] == null) {
    engines[key] = new Engine();
  }
  return engines[key];
};

export const destroyEngine = (key="default") => {
  const engine = engines[key];
  if (engine) {
    engines[key].worker.terminate();
  }
  engines[key] = null;
}

const LINE_REGEX = /(pv) ((\w\d\w\d\s?)+)/
const CP_REGEX = /(cp) (-?\d+)/
const MATE_REGEX = /(mate) (-?\d+)/
const MULTIPV_REGEX = /(multipv) (\d+)/

export const parseMultipvInfo = (message) => {
  if (!MULTIPV_REGEX.test(message)) { return }

  const multipv = parseInt(MULTIPV_REGEX.exec(message)[2], 10);
  const moves = LINE_REGEX.exec(message)[2];

  const scoreMatch = CP_REGEX.exec(message);
  const score = scoreMatch && parseInt(scoreMatch[2], 10);

  const mateMatch = MATE_REGEX.exec(message);
  const mate = mateMatch && parseInt(mateMatch[2], 10);

  const out = { multipv, score, moves, mate };
  return out;
}