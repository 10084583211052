import {
  SET_SETTINGS,
  UPDATE_SETTINGS,
} from '../../constants';

const initialState = {
  chessBoard: 'wood',
  chessSet: 'alpha',
  showLegalMoves: true,
  playSounds: true,
};

export function settings(state=initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SETTINGS:
      return payload;
    case UPDATE_SETTINGS:
      return { ...state, ...payload };
    default:
      return state;
  }
};