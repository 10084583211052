import React from 'react';
import classnames from 'classnames';
import { CHESS_PIECES } from '../../constants';
import './index.css';

export default function ChessPiece(props) {
  const style = {
    width: props.size,
    height: props.size,
    left: props.left,
    top: props.top,
    bottom: props.bottom,
    boxSizing: 'border-box',
  };

  if (props.animationSpeed != null) {
    style.transitionDuration = props.animationSpeed + 'ms';
  }

  const src = CHESS_PIECES[props.chessSet][props.type];
  const className = classnames(
    'ChessPiece', 
    { dragged: props.dragged },
    { clickable: !!props.onClick });

  return (
    <img 
      className={className} 
      onClick={props.onClick} 
      onTouchStart={props.onTouchStart}
      src={src} 
      style={style} 
    />
  );
};