import { getInitialState } from '../api/generic';
import { SET_API_STATUS, SET_API_ERROR, SET_API_DATA, API } from '../../constants';

export function api(state=getInitialState(), action) {
  const { type, payload } = action;
  let key, entry;

  switch (type) {
    case SET_API_STATUS:
      key = payload.key;
      entry = state[key];
      return {
        ...state,
        [key]: {
          ...entry,
          status: payload.status,
          promise: payload.promise,
          error: null,
        }
      };
    case SET_API_ERROR:
      key = payload.key;
      entry = state[key];
      return { ...state, ...payload };
    case SET_API_DATA:
      key = payload.key;
      entry = state[key];
      const data = entry.results.concat(payload.results);
      const count = payload.count;
      const offset = data.length;
      return {
        ...state,
        [key]: {
          ...entry,
          status: API.DEFAULT,
          results: data,
          promise: payload.promise,
          count,
          offset,
          error: null,
        }
      };
    default:
      return state;
  }
};