import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const Card = (props) => {
  return (
    <Link className="Card" to={props.url} onClick={props.onClick}>
      {props.children}
    </Link>
  );
}

export default class LandingPage extends React.Component {
  render() {
    return (
      <div className="LandingPage">
        <div className="Cards">
          <Card url="/puzzles/">
            Puzzles
          </Card>
          <Card url="/training-game/">
            Training Game
          </Card>
        </div>
        <div className="Copy">
          "It's not enough to be a good player, one must also play well."
        </div>
        <div className="Copy">
          - Siegbert Tarrasch
        </div>
      </div>
    );
  }
}