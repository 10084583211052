import React from 'react';
import { connect } from 'react-redux';

import GameViewer from '../../GameViewer';
import * as api from '../../../model/api';
import { TreeManager } from '../../../model/data/TreeManager';
import './index.css';
import { TOP_NAV_HEIGHT } from '../../../constants';

class AnalysisPage extends React.Component {
  static forResourceType

  constructor(props) {
    super(props);
    this.state = {
      puzzle: null,
      treeManager: null,
    };
  }

  componentDidMount() {
    if (this.props.resourceType === 'puzzles' && this.props.id) {
      api.getPuzzle({ id: this.props.id }).then(res => {
        this.setState({
          puzzle: res.data,
          treeManager: TreeManager.load(res.data),
        });
      });
    } else {
      this.setState({
        puzzle: { name: 'Analysis' },
        treeManager: new TreeManager(),
      });
    }
  }

  getLayout() {
    // this is hard.
    // need to compute vertical vs. horizontal layout
    const CONTROLS_MIN_WIDTH = 200;
    const CONTROLS_MIN_HEIGHT = 200;
    const PADDING = 16;
    const { width, height } = this.props.screen;
    const maxBoardSize = Math.min(
      width, 
      height - (TOP_NAV_HEIGHT + PADDING*2)) - PADDING*2;
    const squareSize = Math.floor(maxBoardSize/8);
    return {
      boardSize: squareSize * 8,
    };
  }

  render() {
    const { boardSize } = this.getLayout();

    return (
      <div className="AnalysisPage">
        {this.state.treeManager && 
          <GameViewer
            size={boardSize}
            editable
            title={this.state.puzzle.name}
            tree={this.state.treeManager.tree}
            kibitzer
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => (
  { 
    screen: state.ui.screen,
    id: ownProps.match.params.id 
  }
);
export default connect(mapStateToProps)(AnalysisPage);