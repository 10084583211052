import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import './index.css';

const modalRoot = document.getElementById('modal-root');

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  requestClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onRequestClose();
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const noOverlay = this.props.noOverlay;
    const className = classnames('Modal', { open: this.props.isOpen }, this.props.className);
    const closeButton = this.props.closeButton || "\u00D7";
    this.el.className = className;

    return ReactDOM.createPortal((
      <div className={className}>
        {!noOverlay && 
          <div className="overlay"
            onClick={this.requestClose}
            onTouchStart={this.requestClose}
          >
          </div>
        }
        <div className="ModalContent" onTransitionEnd={this.props.onTransitionEnd}>
          <div className="ModalHeader">
            <div className="close-button" onClick={this.requestClose}>{closeButton}</div>
            <div className="title">{this.props.title}</div>
          </div>
          <div className="body">
            {this.props.children}
          </div>
        </div>
      </div>
    ), this.el);
  }
}