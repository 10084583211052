import React from 'react';
import Markdown from '../../Markdown';
import './index.css';

const copy = `

## Playing chess well is a skill that can be trained.

Skills such as solving a math problem, playing the piano, or driving a car
require a combination of knowledge and skill. 

Knowledge can be obtained through
study, such as reading an article, watching a video, or attending a lecture.

Skill can only be obtained through repeated application of knowledge.

Using the examples above that would involve solving various math problems,
practicing the piano, and practicing driving. In the case of chess practice
includes solving puzzles and playing training games.

Much of chess training materials focuses on the wrong things.

Browsing lightly annotated chess games in video or written format
can be entertaining and informative but rarely results in a
significant increase in playing strength.

## Chess games are won by playing strong moves.

There is far too much focus on the openings. Obtaining a decisive advantage
out of the opening is not possible without cooperation from the opponent, and certainly
not a practical goal even against fallible human opposition.

At some point in every game we reach a position that is fresh to both players, and from
that point on it is primarily the difference between the depth and accuracy of calculation
of the two opponents that decides the result. This is especially the case in matches between
players of significantly different strengths, where the stronger player typically sees more and
sees it more clearly.

Then in many games a technical endgame will be reached where the methods of attack and defense
are well known (although perhaps not to the players). If one of the players has a greater knowledge and 
skill in the playing the endgame this constitues an enormous practical advantage. Indeed one of
the hallmarks of world champion Magnus Carlsen's play is "squeezing blood from a stone" in 
apparently equal endgame positions.

And while opening theory evolves constantly as stronger engines provide more accurate evaluations and
researchers discover new approaches in well known positions, the three pillars of chess skill:
calculation, tactics, and endgames remain the same.`;

export default function AboutPage(props) {
  return (
    <div className="AboutPage">
      <Markdown text={copy}/>
    </div>
  );
}