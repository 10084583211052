import React from 'react';
import { connect } from 'react-redux';
import * as reactRouterDom from 'react-router-dom';
import { isAuthorized } from '../../../model/roles';
import NotFoundPage from '../../Page/NotFound';

class Route extends React.Component {
  checkAuth() {
    if (!this.props.auth) { return true; }
    return isAuthorized(this.props.user, this.props.auth);
  }

  render() {
    const component = this.checkAuth() ? 
      this.props.component :
      this.props.notAuthorizedComponent || NotFoundPage;

    return (
      <reactRouterDom.Route { ...this.props } component={component} />
    );
  }
}

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(Route);