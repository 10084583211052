import { RESIZE } from './constants';
import { saveSessionInLocalStorage, saveSettingsInLocalStorage } from './model/localStorage';

export function addEventListeners(store) {
  window.addEventListener('resize', (e) => {
    store.dispatch({
      type: RESIZE,
      payload: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  });
}

