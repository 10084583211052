const SETTINGS = 'settings';
const SESSION = 'session';

export const getSessionFromLocalStorage = () => {
  return JSON.parse(window.localStorage.getItem(SESSION));
}

export const saveSessionInLocalStorage = (session) => {
  const _session = { user: session.uer, token: session.token };
  window.localStorage.setItem(SESSION, JSON.stringify(_session));
}

export const clearSessionInLocalStorage = () => {
  window.localStorage.removeItem(SESSION);
}

export const getSettingsFromLocalStorage = () => {
  return JSON.parse(window.localStorage.getItem(SETTINGS));
}

export const saveSettingsInLocalStorage = settings => {
  window.localStorage.setItem(SETTINGS, JSON.stringify(settings));
}

export const clearSettingsInLocalStorage = () => {
  window.localStorage.removeItem(SETTINGS);
}