import Chess from './Chess';
import { FEN_START_POSITION } from '../../constants';

export function deserialize(root, fen=root.fen, path = [], san = null, isRoot=true) {
  const children = [];
  const tree = new VariationTree({
    children,
    san,
    fen,
    path,
  });

  if (root.textAfterMove) { tree.textAfterMove = root.textAfterMove; }
  if (root.textBeforeMove) { tree.textBeforeMove = root.textBeforeMove; }
  if (root.annotation) { tree.annotation = root.annotation; }

  let _children = root.children || {};
  console.log(_children);
  if (typeof _children === 'string') { _children = JSON.parse(_children); }

  const moves = Object.keys(_children || {});

  moves.sort((a, b) => {
    const p1 = _children[a].priority || 1;
    const p2 = _children[b].priority || 1;
    return p1 - p2;
  });

  moves.forEach(san => {
    const _path = [...path, san];
    const child = _children[san];
    const _chess = new Chess(fen);
    _chess.move(san);
    children.push(deserialize(child, _chess.fen(), _path, san, false));
  });

  return tree;
}

export function serialize(tree, isRoot=true) {
  const out = {};
  if (isRoot) { out.fen = tree.fen; }
  if (tree.textAfterMove) { out.textAfterMove = tree.textAfterMove }
  if (tree.textBeforeMove) { out.textBeforeMove = tree.textBeforeMove }
  if (tree.annotation) { out.annotation = tree.annotation }
  out.children = {};
  tree.children.forEach((child, index) => {
    const _child = serialize(child, false);
    _child.priority = index;
    out.children[child.san] = _child;
  });
  if (isRoot) {
    out.children = JSON.stringify(out.children);
  }
  return out;
}

export function findChildIndex(tree, criteria) {
  const { children } = tree;
  const keys = Object.keys(criteria);
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    let matched = true;
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      if (criteria[key] !== child[key]) {
        matched = false;
        break;
      }
    }
    if (matched) {
      return i;
    }
  }
  return -1;
}

export function hasChild(tree, criteria) {
  return findChildIndex(tree, criteria) !== -1;
}

export function findChild(tree, criteria) {
  const index = findChildIndex(tree, criteria);
  return index === -1 ? null : tree.children[index];
}

export function updateChildren(tree, fn) {
  const _tree = tree.copy();
  _tree.children = fn(_tree.children);
  return _tree;
}

export function updateChildrenAt(tree, path, fn) {
  if (path.length === 0) {
    return updateChildren(tree, fn);
  }

  const index = findChildIndex(tree, { san: path[0] });
  if (index === -1) { throw Error('invalid path') };

  return updateChildren(tree, children => {
    const _path = path.slice(1);
    const _children = children.slice();
    const child = _children[index];
    _children[index] = updateChildrenAt(child, _path, fn);
    return _children;
  });
}

export class VariationTree {
  constructor(props = {}) {
    const {
      children = [],
      san = null,
      fen = FEN_START_POSITION,
      path = [],
      textBeforeMove = null,
      textAfterMove = null,
      annotation = null,
    } = props;
    this.children = children;
    this.san = san;
    this.fen = fen;
    this.path = path;
    this.annotation = annotation;
    this.textBeforeMove = textBeforeMove;
    this.textAfterMove = textAfterMove;
  }

  isEmpty() {
    return this.children.length === 0;
  }

  copy() {
    return new VariationTree(this);
  }

  edit(props) {
    return new VariationTree({ ...this, ...props });
  }

  toJSON() {
    return serialize(this);
  }

  static fromJSON(json) {
    return deserialize(json);
  }
}