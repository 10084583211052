import WikiBlackBishop from './chesspieces/wikipedia/bB.png';
import WikiBlackKnight from './chesspieces/wikipedia/bN.png';
import WikiBlackKing from './chesspieces/wikipedia/bK.png';
import WikiBlackPawn from './chesspieces/wikipedia/bP.png';
import WikiBlackQueen from './chesspieces/wikipedia/bQ.png';
import WikiBlackRook from './chesspieces/wikipedia/bR.png';
import WikiWhiteBishop from './chesspieces/wikipedia/wB.png';
import WikiWhiteKnight from './chesspieces/wikipedia/wN.png';
import WikiWhiteKing from './chesspieces/wikipedia/wK.png';
import WikiWhitePawn from './chesspieces/wikipedia/wP.png';
import WikiWhiteQueen from './chesspieces/wikipedia/wQ.png';
import WikiWhiteRook from './chesspieces/wikipedia/wR.png';

import UscfBlackBishop from './chesspieces/uscf/bB.png';
import UscfBlackKnight from './chesspieces/uscf/bN.png';
import UscfBlackKing from './chesspieces/uscf/bK.png';
import UscfBlackPawn from './chesspieces/uscf/bP.png';
import UscfBlackQueen from './chesspieces/uscf/bQ.png';
import UscfBlackRook from './chesspieces/uscf/bR.png';
import UscfWhiteBishop from './chesspieces/uscf/wB.png';
import UscfWhiteKnight from './chesspieces/uscf/wN.png';
import UscfWhiteKing from './chesspieces/uscf/wK.png';
import UscfWhitePawn from './chesspieces/uscf/wP.png';
import UscfWhiteQueen from './chesspieces/uscf/wQ.png';
import UscfWhiteRook from './chesspieces/uscf/wR.png';

import AlphaBlackBishop from './chesspieces/alpha/bB.png';
import AlphaBlackKnight from './chesspieces/alpha/bN.png';
import AlphaBlackKing from './chesspieces/alpha/bK.png';
import AlphaBlackPawn from './chesspieces/alpha/bP.png';
import AlphaBlackQueen from './chesspieces/alpha/bQ.png';
import AlphaBlackRook from './chesspieces/alpha/bR.png';
import AlphaWhiteBishop from './chesspieces/alpha/wB.png';
import AlphaWhiteKnight from './chesspieces/alpha/wN.png';
import AlphaWhiteKing from './chesspieces/alpha/wK.png';
import AlphaWhitePawn from './chesspieces/alpha/wP.png';
import AlphaWhiteQueen from './chesspieces/alpha/wQ.png';
import AlphaWhiteRook from './chesspieces/alpha/wR.png';

import ElaborateBlackBishop from './chesspieces/elaborate/bB.svg';
import ElaborateBlackKnight from './chesspieces/elaborate/bN.svg';
import ElaborateBlackKing from './chesspieces/elaborate/bK.svg';
import ElaborateBlackPawn from './chesspieces/elaborate/bP.svg';
import ElaborateBlackQueen from './chesspieces/elaborate/bQ.svg';
import ElaborateBlackRook from './chesspieces/elaborate/bR.svg';
import ElaborateWhiteBishop from './chesspieces/elaborate/wB.svg';
import ElaborateWhiteKnight from './chesspieces/elaborate/wN.svg';
import ElaborateWhiteKing from './chesspieces/elaborate/wK.svg';
import ElaborateWhitePawn from './chesspieces/elaborate/wP.svg';
import ElaborateWhiteQueen from './chesspieces/elaborate/wQ.svg';
import ElaborateWhiteRook from './chesspieces/elaborate/wR.svg';

import RedX from './ui/red-x.png';
import X from './ui/x.png';
import Pointer from './ui/pointer.png';
import CubeSpinner from './ui/CubeSpinner.gif';
import Hamburger from './ui/hamburger.png';
import Logo from './ui/logo.png';
import CircleX from './ui/circle-x-mark.svg';
import Checkmark from './ui/checkmark.svg';
import Share from './ui/share.svg';
import Capture1 from './audio/capture1.wav';
import Capture2 from './audio/capture2.wav';
import Piece1 from './audio/piece1.wav';
import Piece2 from './audio/piece2.wav';
import Setup1 from './audio/setup1.wav';
import Setup2 from './audio/setup2.wav';
import { Howl } from 'howler';

export const CHESS_PIECES = {
  wikipedia: {
    bb: WikiBlackBishop,
    bn: WikiBlackKnight,
    bk: WikiBlackKing,
    bp: WikiBlackPawn,
    bq: WikiBlackQueen,
    br: WikiBlackRook,
    wb: WikiWhiteBishop,
    wn: WikiWhiteKnight,
    wk: WikiWhiteKing,
    wp: WikiWhitePawn,
    wq: WikiWhiteQueen,
    wr: WikiWhiteRook,
  },
  uscf: {
    bb: UscfBlackBishop,
    bn: UscfBlackKnight,
    bk: UscfBlackKing,
    bp: UscfBlackPawn,
    bq: UscfBlackQueen,
    br: UscfBlackRook,
    wb: UscfWhiteBishop,
    wn: UscfWhiteKnight,
    wk: UscfWhiteKing,
    wp: UscfWhitePawn,
    wq: UscfWhiteQueen,
    wr: UscfWhiteRook,
  },
  alpha: {
    bb: AlphaBlackBishop,
    bn: AlphaBlackKnight,
    bk: AlphaBlackKing,
    bp: AlphaBlackPawn,
    bq: AlphaBlackQueen,
    br: AlphaBlackRook,
    wb: AlphaWhiteBishop,
    wn: AlphaWhiteKnight,
    wk: AlphaWhiteKing,
    wp: AlphaWhitePawn,
    wq: AlphaWhiteQueen,
    wr: AlphaWhiteRook,
  },
  elaborate: {
    bb: ElaborateBlackBishop,
    bn: ElaborateBlackKnight,
    bk: ElaborateBlackKing,
    bp: ElaborateBlackPawn,
    bq: ElaborateBlackQueen,
    br: ElaborateBlackRook,
    wb: ElaborateWhiteBishop,
    wn: ElaborateWhiteKnight,
    wk: ElaborateWhiteKing,
    wp: ElaborateWhitePawn,
    wq: ElaborateWhiteQueen,
    wr: ElaborateWhiteRook,
  },
};

export const CHESS_SETS = {
  WIKIPEDIA: 'wikipedia',
  ELABORATE: 'elaborate',
  USCF: 'uscf',
  ALPHA: 'alpha',
};

export const ASSETS = {
  LOGO: Logo,
  HAMBURGER: Hamburger,
  X: X, 
  POINTER: Pointer,
  CUBE_SPINNER: CubeSpinner,
  RED_X: RedX,
  CIRCLE_X: CircleX,
  CHECKMARK: Checkmark,
  SHARE: Share,
};

export const AUDIO = {
  CAPTURE: [Capture1, Capture2],
  PIECE: [Piece1, Piece2],
  SETUP: [Setup1, Setup2],
}

export const playSound = (sound) => {
  const howl = new Howl({
    src: [sound]
  });
  howl.play();
} 

export const playRandomSound = (sounds) => {
  const idx = Math.floor(Math.random() * sounds.length);
  playSound(sounds[idx]);
}