import React from 'react';
import Field from '../Field';
import '../index.css';
import './index.css';

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      buttonFocused: false,
    };
  }
  onPasswordChange = () => {}
  onPasswordConfirmationChange = () => {}

  render() {
    return (
      <div className="PasswordResetForm">
        <div className="Copy">
          Enter your new password
        </div>
        <Field
          label="Password"
          type="password"
          onChange={this.onPasswordChange}
        />
        <Field
          label="Password Confirmation"
          type="password"
          onChange={this.onPasswordConfirmationChange}
        />
        <button
          className="Button Action" 
        >
          Set Password
        </button>
      </div>
    );
  }
}

export default PasswordResetForm;