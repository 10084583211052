import React from 'react';
import classnames from 'classnames';
import Field from '../Field';
import './index.css';

export default class ForgotPasswordForm extends React.Component {
  render() {
    const className = classnames('ForgotPasswordForm', { hasErrors: this.props.hasErrors });

    return (
      <div className={className}>
        <div className="Copy">
          Enter the email address associated with your account, and we'll email you a
          link to reset your password.
        </div>
        <Field
          hasErrors={!!this.props.errors}
          type="text"
          label="Email"
          value={this.props.email}
          onChange={this.props.onEmailChange}
        />
        <div className="Button" onClick={this.props.onSubmit}>Send reset link</div>
      </div>
    );
  }
}