import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { TOP_NAV_HEIGHT, PUZZLE_CONTROLS_MIN_SIZE } from '../../../../constants';
import { ASSETS } from '../../../../assets';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { TreeManager } from '../../../../model/data/TreeManager';
import { fetchPuzzles, notify } from '../../../../model/actions';
import * as api from '../../../../model/api';
import PuzzlePlayer from '../../../PuzzlePlayer';
import VariationControls from '../../../VariationControls';
import VariationView from '../../../VariationView';
import Kibitzer from '../../../Kibitzer';
import Modal from '../../../Modal';
import './index.css';
import ChessBoard from '../../../ChessBoard';
import { copyToClipboard } from '../../../../util';

const MODE = {
  LOADING: 'LOADING',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  ENDED: 'ENDED',
  ANALYSIS: 'ANALYSIS',
}

const FILTERS = {
  freestyle: '',
  spanish: 'C6 C7 C8 C9',
  najdorf: 'B9',
  dragon: 'B7',
  french: 'C0 C1',
  'queens-gambit': 'D3 D4 D5',
  grunfeld: 'D7 D8 D9',
}

class PuzzleFreestylePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestPending: false,
      puzzleStartTime: false,
      puzzles: [],
      playerColor: 'w',
      mode: MODE.LOADING,
      puzzleIndex: 0,
      treeManager: new TreeManager(),
      sessionHistory: [],
      madeMistake: false,
      analysisShown: false,
      hintShown: false,
      numMistakes: 0,
      difficulty: 0,
      errorResponse: null,
      showModal: false,
    }
  }

  componentDidMount() {
    if (this.props.puzzleId) {
      this.loadPuzzle(this.props.puzzleId);
    } else {
      this.loadPuzzles();
    }
  }

  isLoading() {
    return this.state.mode === MODE.LOADING;
  }

  isInProgress() {
    return this.state.mode === MODE.IN_PROGRESS;
  }

  isPaused() {
    return this.state.mode === MODE.PAUSED;
  }

  loadPuzzle() {
    const { puzzleId } = this.props;
    this.setState({
      requestPending: true,
    });
    return api.getPuzzle({ id: puzzleId })
      .then(res => {
        const puzzle = res.data;
        this.setState({
          puzzles: [puzzle],
        }, () => { this.startNextPuzzle() });
      });
  }

  loadPuzzles() {
    const { requestPending, puzzles, noMorePuzzles } = this.state;
    if (requestPending || noMorePuzzles) { return; }
    this.setState({
      requestPending: true,
    });
    return api.getPuzzles({
      eco: this.props.filter,
    }).then(res => {
      if (res.data.results.length === 0) {
        this.setState({
          mode: MODE.ENDED,
          requestPending: false,
        });
        return;
      }

      this.setState({
        requestPending: false,
        puzzles: this.state.puzzles.concat(res.data.results),
      }, () => { this.startNextPuzzle() });
    }).catch(err => {
      const data = err.response && err.response.data;
      const reason = data && data.reason;
      const limitExceeded = reason === 'DAILY_LIMIT_EXCEEDED';

      this.setState({
        errorResponse: err.response,
        mode: MODE.PAUSED,
        showModal: limitExceeded,
      });
    });
  }

  limitWasExceeded = () => {
    const err = this.state.errorResponse;
    const data = err && err.data;
    const reason = data && data.reason;
    const limitExceeded = reason === 'DAILY_LIMIT_EXCEEDED';
    return !!limitExceeded;
  }

  handleRetry = () => {
    const treeManager = TreeManager.load(this.getPuzzle());
    this.setState({
      mode: MODE.IN_PROGRESS,
      treeManager,
      madeMistake: false,
    });
  }

  startNextPuzzle() {
    const { puzzleIndex, puzzles } = this.state;
    
    if (puzzleIndex >= puzzles.length) {
      this.loadPuzzles();
      this.setState({
        MODE: MODE.LOADING,
      });
      return;
    }

    const puzzle = puzzles[puzzleIndex];
    const nextPuzzleIndex = this.state.puzzleIndex + 1;
    const treeManager = TreeManager.load(puzzle);

    this.setState({
      mode: MODE.IN_PROGRESS,
      playerColor: treeManager.getTurn(),
      puzzleIndex: nextPuzzleIndex,
      treeManager,
      madeMistake: false,
      analysisShown: false,
    });
  }

  handleGoodMove = () => {
  }

  handleBadMove = () => {
    this.sendInteraction(false);

    this.setState({
      mode: MODE.PAUSED,
      madeMistake: true,
      numMistakes: this.state.numMistakes + 1,
      sessionHistory: [...this.state.sessionHistory, false],
    });
  }

  handleSolvedPuzzle = () => {
    this.sendInteraction(true);
    
    this.setState({
      mode: MODE.PAUSED,
      sessionHistory: [...this.state.sessionHistory, true],
    });
  }

  sendInteraction = (success) => {
    if (!this.props.user) {
      return;
    }

    // can't send time spent until backend is updated
    // FIXME send time spent

    const data = {
      succeeded: success,
      puzzle: this.state.puzzles[this.state.puzzleIndex-1].id,
      user: this.props.user.id,
    };

    api.sendInteraction(data);
  }

  getPuzzle() {
    return this.state.puzzles[this.state.puzzleIndex-1];
  }

  renderAnalysisButton() {
    const puzzle = this.getPuzzle();
    if (!puzzle) { return; }
    return (
      <div 
        className="Solution Button Action" 
        onClick={() => {
          this.setState({
            mode: MODE.ANALYSIS,
            analysisShown: true,
          })
        }}
      >
        Analyse
      </div>
    );
  }

  handleShare = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const puzzle = this.getPuzzle();
    const { protocol, hostname, port } = window.location;
    const url = `${protocol}//${hostname}${port ? ':'+port : ''}/puzzles/shareable/${puzzle.uuid}/`;

    if (navigator.share) {
      navigator.share({
        title: 'ChessTechnique.com',
        text: puzzle.name,
        url, 
      })
      .then(() => {})
      .catch(_ => {
        window.location.reload(true);
      });
      return;
    }

    copyToClipboard(url);
    this.props.notify('Copied shareable puzzle link to clipboard!');
  }
  
  handleContinue = () => {
    const isStandalonePuzzle = !!this.props.puzzleId;
    if (isStandalonePuzzle) {
      this.props.push('/puzzles/');
    }

    const limitExceeded = this.limitWasExceeded();
    if (!limitExceeded) {
      this.startNextPuzzle();
    }
  }

  renderContinueButton() {
    const limitExceeded = this.limitWasExceeded();

    const continueButtonClassName = classnames(
      'Button Action Continue', 
      { disabled: limitExceeded },
    );

    const continueButton = (
      <div 
        className={continueButtonClassName} 
        onClick={this.handleContinue}
      >
        Continue
      </div>
    );

    return continueButton;
  }

  renderInProgressControls() {
    const turn = this.state.playerColor === 'w' ? 'White' : 'Black';
    const turnText = `${turn} to Play`;
    const className = classnames('Status', turn);

    return [
      <div className={className}>{turnText}</div>,
    ];
  }

  renderEndedControls() {
    const statusText = 'Session Ended';
    return [<div className="Status">{statusText}</div>];
  }

  renderLoadingControls() {
    const statusText = 'Loading ...';
    return [<div className="Status">{statusText}</div>];
  }

  renderPausedControls() {
    let statusText = 'Correct!';
    let className = classnames('Status', 'Correct');
    let limitExceeded = this.limitWasExceeded();
    let retryButton;

    if (limitExceeded && this.state.sessionHistory.length === 0) {
      statusText = 'Limit Exceeded';
      className = classnames('Status');
    }

    if (this.state.madeMistake) {
      statusText = 'Incorrect';
      className = classnames('Status', 'Incorrect');
      retryButton = (
        <div className="Button Action" onClick={this.handleRetry}>Retry</div>
      );
    }

    const status = (
      <div className={className}>{statusText}</div>
    );

    const continueButton = this.renderContinueButton();

    return [
      status,
      retryButton,
      continueButton,
      this.renderAnalysisButton(),
    ];
  }

  renderAnalysisControls() {
    const limitExceeded = this.limitWasExceeded();
    const continueButtonClassName = classnames(
      'Button Action Continue', 
      { disabled: limitExceeded },
    );
    const continueButton = (
      <div 
        className={continueButtonClassName} 
        onClick={() => !limitExceeded && this.startNextPuzzle()}
      >
        Continue
      </div>
    );

    const variationView = (
      <VariationView
        root={this.state.treeManager.tree}
        currentPath={this.state.treeManager.node.path} 
        onClick={this.setCurrentNode}
      />
    );

    const variationControls = (
      <VariationControls 
        moveToBeginning={this.moveToBeginning}
        moveToEnd={this.moveToEnd}
        moveForward={this.moveForward}
        moveBackward={this.moveBackward}
      />
    );

    const kibitzer = (
      <Kibitzer 
        auto
        fen={this.state.treeManager.node.fen} 
      />
    )

    return [
      continueButton,
      variationControls,
      variationView,
      kibitzer,
    ];
  }

  setTreeManager(treeManager) {
    this.setState({
      treeManager,
    });
  }

  moveForward = () => {
    this.setTreeManager(this.state.treeManager.moveForward());
  }

  moveBackward = () => {
    this.setTreeManager(this.state.treeManager.moveBackward());
  }

  moveToBeginning = () => {
    this.setTreeManager(this.state.treeManager.moveToBeginning());
  }

  moveToEnd = () => {
    this.setTreeManager(this.state.treeManager.moveToEnd());
  }

  handleAnalysisMove = (move) => {
    this.setTreeManager(this.state.treeManager.executeMove(move));
  }

  setCurrentNode = (node) => {
    console.log(node);
    this.setTreeManager(this.state.treeManager.moveTo(node.path));
  }

  renderControls(controlsStyle) {
    let content;

    switch (this.state.mode) {
      case MODE.LOADING:
        content = this.renderLoadingControls();
        break;
      case MODE.IN_PROGRESS:
        content = this.renderInProgressControls();
        break;
      case MODE.PAUSED:
        content = this.renderPausedControls();
        break;
      case MODE.ENDED:
        content = this.renderEndedControls();
        break;
      case MODE.ANALYSIS:
        content = this.renderAnalysisControls();
        break;
    }

    return (
      <div className="PuzzleControls" style={controlsStyle}>
        {content}
      </div> 
    );
  }

  getDesktopLayout() {
    const padding = 16;
    const { width, height } = this.props.screen;
    const boardMaxSize = Math.min(
      width - PUZZLE_CONTROLS_MIN_SIZE, 
      height - TOP_NAV_HEIGHT
    ) - padding * 2;
    const squareSize = Math.floor(boardMaxSize / 8);
    const boardSize = squareSize * 8;
    let controlsWidth = Math.min(width-boardSize-padding*3, 300);

    const controlsStyle = {
      position: 'absolute',
      top: 0,
      left: boardSize + padding,
      width: controlsWidth,
      height: boardSize,
    };

    return {
      boardSize,
      controlsStyle,
      containerPadding: Math.floor((width - (boardSize + controlsWidth + padding)) / 2),
    };
  }

  getMobileLayout() {
    const { width, height } = this.props.screen;
    const boardMaxSize = Math.min(width - 24, height);
    const squareSize = Math.floor(boardMaxSize / 8);
    const boardSize = squareSize * 8;

    const controlsStyle = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: height - boardSize - TOP_NAV_HEIGHT - 32,
    };

    return {
      boardSize,
      controlsStyle,
    };
  }

  getLayout() {
    const { width } = this.props.screen;
    return width <= 600 ? this.getMobileLayout() : this.getDesktopLayout();
  }

  renderShareButton() {
    return (
      <img className="Share" src={ASSETS.SHARE} onClick={this.handleShare}></img>
    )
  }

  renderLimitExceededModal() {
    if (!this.state.showModal) {
      return (<Modal isOpen={false}/>);
    }

    const data = this.state.errorResponse && this.state.errorResponse.data;
    const limit = data.limit;
    const time = 'tomorrow';
    return (
      <Modal
        className="DailyLimitExceededModal"
        title="Daily Limit Exceeded"
        isOpen={this.state.showModal}
        onRequestClose={() => { this.setState({ showModal: false }) }}
      >
        <div>Non-subscribers are limited to {limit} puzzles per day.</div>
        <div>Please come back {time} or <Link className="link" to="/premium/">become a premium subscriber!</Link></div>
      </Modal>
    )
  }

  render() {
    const { boardSize, controlsStyle, containerPadding } = this.getLayout();

    let style;
    if (containerPadding) {
      style = { padding: `16px ${containerPadding}px 0 ${containerPadding}px` };
    }

    let chessContent;

    if (this.state.mode !== MODE.ANALYSIS) {
      chessContent = (
        <div className="container">
          <PuzzlePlayer
            playSounds={this.props.playSounds}
            playable={this.state.mode === MODE.IN_PROGRESS}
            treeManager={this.state.treeManager}
            onGoodMove={this.handleGoodMove}
            onBadMove={this.handleBadMove}
            onSolvedPuzzle={this.handleSolvedPuzzle}
            size={boardSize}
          />
          {this.renderControls(controlsStyle, boardSize)}
        </div>
      );
    } else {
      chessContent = (
        <div className="container">
          <div className="board-section">
            <ChessBoard
              size={boardSize}
              playSounds={this.props.playSounds}
              draggable
              fen={this.state.treeManager.node.fen}
              sendMove={this.handleAnalysisMove}
            />
          </div>
          {this.renderControls(controlsStyle, boardSize)}
          {this.renderShareButton()}
        </div>
      )
    }

    return (
      <div className="PuzzlePage" style={style}>
        {chessContent}
        {this.renderLimitExceededModal()}
        {this.renderShareButton()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { filter, puzzleId } = ownProps.match.params;
  console.log({
    filter,
    puzzleId,
  });

  return {
    puzzleId,
    puzzles: state.puzzles,
    screen: state.ui.screen,
    user: state.session.user,
    playSounds: state.settings.playSounds,
    filter: FILTERS[filter],
  };
};

const mapDispatchToProps = {
  fetchPuzzles,
  push,
  notify,
};

export default connect(mapStateToProps, mapDispatchToProps)(PuzzleFreestylePage);