import React from 'react';
import { Link } from 'react-router-dom';
import AccountMenu from '../AccountMenu';
import { ASSETS } from '../../assets';
import '../../styles/chrome.css';
import './index.css';

export default function NavBar(props) {
  return (
    <nav className="NavBar">
      <div className="inner-container">
        <div className="Logo">
          <Link to="/">
            <img src={ASSETS.LOGO} />
            <div className="SiteName">
              ChessTechnique.com
            </div>
          </Link>
        </div>
        <AccountMenu />
      </div>
    </nav>
  );
}