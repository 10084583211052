import React from 'react';
import Field from '../Field';
import '../index.css';
import './index.css';

class RegistrationForm extends React.Component {
  renderFormErrors() {
    const { errors } = this.props;
    console.log(errors);
    if (errors && errors.non_field_errors) {
      const content = errors.non_field_errors.map(error => (
        <div className="error">{error}</div>
      ));
      return (
        <div className="Errors">
          {content}
        </div>
      );
    }
  }

  render() {
    return (
      <form className="RegistrationForm">
        <Field
          errors={this.props.errors && this.props.errors.email}
          label="Email"
          type="text"
          value={this.props.email}
          onChange={this.props.onEmailChange}
        />
        <Field
          errors={this.props.errors && this.props.errors.password}
          label="Password"
          type="password"
          value={this.props.password}
          onChange={this.props.onPasswordChange}
        />
        {this.renderFormErrors()}
        <button className="Button Action" onClick={this.props.onSubmit}>Register</button>
      </form>
    );
  }
}

export default RegistrationForm;