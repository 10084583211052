import React from 'react';
import classnames from 'classnames';
import './index.css';
import Autoheight from '../../Autoheight';

export default class Field extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  handleClick = (_) => {
    this.inputRef.current.focus();
  }

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  }

  renderErrors() {
    if (!this.props.errors) {
      return;
    }

    return (
      <div className="Errors">
        { this.props.errors.map(msg => <div className="Message">{msg}</div>) }
      </div>
    );
  }

  render() {
    const notEmpty = !!this.props.value;
    const hasErrors = !!this.props.hasErrors;
    const className = classnames('Field', { notEmpty }, { hasErrors });
    return (
      <div className="FieldContainer">
        <div className={className}
          onClick={this.handleClick}
        >
          <div className="label">{this.props.label}</div>
          <input 
            spellCheck="false"
            ref={this.inputRef}
            type={this.props.type}
            value={this.props.value}
            onChange={this.handleChange}
          />
        </div>
        <Autoheight>
          {this.renderErrors()}
        </Autoheight>
      </div>
    );
  }
}