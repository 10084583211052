import React from 'react';
import Field from '../Field';
import '../index.css';
import './index.css';

export default class LoginForm extends React.Component {
  renderFormErrors() {
    const { errors } = this.props;
    if (errors && errors.non_field_errors) {
      const content = errors.non_field_errors.map(error => (
        <div className="error">{error}</div>
      ));
      return (
        <div className="Errors">
          {content}
        </div>
      );
    }
  }

  render() {
    const hasErrors = !!this.props.errors;
    return (
      <form className="LoginForm">
        <Field
          hasErrors={hasErrors}
          label="Email"
          type="text"
          value={this.props.email}
          onChange={this.props.onEmailChange}
        />
        <Field
          hasErrors={hasErrors}
          label="Password"
          type="password"
          value={this.props.password}
          onChange={this.props.onPasswordChange}
        />
        <div className="keepMeLoggedIn">
          <div className="Label">Keep me logged in</div>
          <input 
            type="checkbox" 
            onChange={this.props.onKeepMeLoggedInChange}
            checked={this.props.keepMeLoggedIn}
          />
        </div>
        {this.renderFormErrors()}
        <button className="Button Action" onClick={this.props.onSubmit}>Log in</button>
        <div className="forgot-password-link" onClick={this.props.onForgotPassword}>
          Forgot your password?
        </div>
      </form>
    )
  }
}

// const LoginForm = (props) => (
//   <div className="LoginForm" />

//   <Form
//     onSubmit={props.onSubmit}
//     validate={values => {
//       const errors = {};
//       if (!values.email) {
//         errors.email = ['Required'];
//       } else if (
//         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
//       ) {
//         errors.email = ['Invalid email address'];
//       }

//       if (!values.password) {
//         errors.password = ['Required'];
//       }

//       return errors;
//     }}
//     render={({
//       submitError,
//       handleSubmit,
//       form,
//       submitting,
//       pristine,
//       values,
//     }) => (
//       <form onSubmit={handleSubmit}>
//         <Field name="email">
//           {({ input, meta }) => (
//             <div className="section">
//               <div className="row">
//                 <label>Email</label>
//                 <div className="input">
//                   <input {...input} type="text" placeholder="Email" />
//                   { processErrorMessages(meta) }
//                 </div>
//               </div>
//             </div>
//           )}
//         </Field>
//         <Field name="password">
//           {({ input, meta }) => (
//             <div className="section">
//               <div className="row">
//                 <label>Password</label>
//                 <div className="input">
//                   <input {...input} type="password" placeholder="Password" />
//                   { processErrorMessages(meta) }
//                 </div>
//               </div>
//             </div>
//           )}
//         </Field>
//         <div className="section keep-me-logged-in">
//           <div className="row">
//             <label>Keep me logged in</label>
//             <input 
//               type="checkbox" 
//               checked={props.keepMeLoggedIn}
//               onChange={props.onKeepMeLoggedInChange}
//             />
//           </div>
//         </div>
//         {submitError && <div className="errors">{submitError}</div>}
//         <div className="buttons">
//           <button className="Action Button submit" type="submit">Login</button>
//           <button className="Button" type="button" onClick={form.reset} disabled={submitting || pristine}>Reset</button>                
//         </div>
//         <div className="forgot-password-link" onClick={props.onForgotPassword}>
//           Forgot your password?
//         </div>
//       </form>
//     )}
//   />
// );

// export default LoginForm;