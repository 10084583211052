import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router';

import * as serviceWorker from './serviceWorker';
import { getStore, history } from './model'
import { addEventListeners } from './events';
import App from './components/App';
import './styles/themes.css';
import './styles/vars.css';
import './styles/chrome.css';
import './styles/index.css';
import './styles/buttons.css';
import './styles/animations.css';

const THEME = 'blue-theme';

document.documentElement.classList.add(THEME);

const store = getStore();
addEventListeners(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App/>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
