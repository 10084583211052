import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import ForgotPasswordForm from '../../Forms/ForgotPassword';
import LoginForm from '../../Forms/Login';
import Modal from '../';
import { login } from '../../../model/api';
import { setToken, fetchCurrentUser, loginWasPrompted } from '../../../model/actions';
import { LEFT_BRACKET } from '../../../constants';
import { isValidEmail } from '../../../util';
import * as api from '../../../model/api';
import { notify } from '../../../model/actions';
import './index.css';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shake: false,
      loginFormErrors: null,
      passwordFormErrors: null,
      email: '',
      password: '',
      keepMeLoggedIn: true,
      showForgotPasswordForm: false,
    };
    this.modalRef = React.createRef();
  }

  toggleForgotPasswordForm = () => {
    this.setState({
      showForgotPasswordForm: !this.state.showForgotPasswordForm,
    });
  }

  shake() {
    this.setState({
      shake: true,
    });
    setTimeout(() => {
      this.setState({
        shake: false,
      });
    }, 1000);
  }

  handleLogin = (e) => {
    e.preventDefault();
    const { email, password, keepMeLoggedIn } = this.state;
    const params = { email, password };
    return login(params)
      .then((res) => {
        this.props.setToken(res.data.auth_token, keepMeLoggedIn);
        this.props.fetchCurrentUser();
        this.props.loginWasPrompted();
        this.props.onRequestClose();
        return res;
      })
      .catch(error => {
        this.shake();
        if (error.response && error.response.data) {
          this.setState({
            loginFormErrors: error.response.data,
          });
        } else {
          // FIXME need to potentially handle network error here
          return Promise.reject(error);
        }
      });
  }

  handleEmailChange = email => {
    this.setState({
      email,
    });
  };

  handlePasswordChange = password => {
    this.setState({
      password,
    });
  }

  handleKeepMeLoggedInChange = (e) => {
    this.setState({
      keepMeLoggedIn: !!e.target.checked,
    });
  }

  handleClose = () => {
    if (this.state.showForgotPasswordForm) {
      this.toggleForgotPasswordForm();
    } else {
      this.props.loginWasPrompted();
      this.props.onRequestClose();
    }
  }

  handleForgotPassword = () => {
    const { email } = this.state;
    if (!isValidEmail(email)) {
      this.shake();
    } else {
      api.resetPassword({ email }).then(() => {
        this.props.notify('Check your email for password reset instructions!');
        this.setState({ showForgotPasswordForm: false });
        this.props.loginWasPrompted();
        this.props.onRequestClose();
      });
    }
  }

  render() {
    let form;
    let title;

    if (!this.state.showForgotPasswordForm) {
      title = 'Log in';
      form = (
        <LoginForm
          key="login"
          onSubmit={this.handleLogin}
          onEmailChange={this.handleEmailChange}
          onPasswordChange={this.handlePasswordChange}
          onKeepMeLoggedInChange={this.handleKeepMeLoggedInChange}
          keepMeLoggedIn={this.state.keepMeLoggedIn}
          email={this.state.email}
          password={this.state.password}
          onForgotPassword={this.toggleForgotPasswordForm}
          errors={this.state.loginFormErrors}
        />
      );
    } else {
      title = 'Forgot password?'
      form = (
        <ForgotPasswordForm
          key="forgot-password"
          email={this.state.email}
          onEmailChange={this.handleEmailChange}
          onSubmit={this.handleForgotPassword}
        />
      );
    }

    const modalClassNames = classnames(
      { forgotPassword: this.state.showForgotPasswordForm },
      { shake: this.state.shake },
      { static: this.props.shouldNotAnimate },
      { 'no-overlay': this.props.noOverlay },
    );

    const registrationLink = (
      <div className="RegistrationLink" onClick={this.props.onNoAccount}>
        Don't have an account?
      </div>
    );

    return (
      <Modal
        className={modalClassNames}
        ref={this.modalRef}
        title={title}
        isOpen={this.props.isOpen || this.props.promptLogin}
        onRequestClose={this.handleClose}
        closeButton={this.state.showForgotPasswordForm ? LEFT_BRACKET : null}
        onTransitionEnd={this.props.onTransitionEnd}
      >
        {form}
        {!this.state.showForgotPasswordForm && registrationLink}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  promptLogin: state.session.promptLogin,
});

 const mapDispatchToProps = {
  setToken,
  fetchCurrentUser,
  notify,
  loginWasPrompted,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

 