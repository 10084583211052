import React from 'react';
import classnames from 'classnames';
import './index.css';

export default class AsyncButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
  }

  handleClick = (e) => {
    const disabled = this.props.disabled || this.state.disabled;
    if (disabled) { return; }

    if (!this.props.onClick) {
      return;
    }

    this.setState({ disabled: true });
    this.props.onClick().finally(() => {
      this.setState({
        disabled: false,
      });
    });
  }

  render() {
    const { danger, caution } = this.props;
    const disabled = this.props.disabled || this.state.disabled;
    const className = classnames(
      this.props.className,
      'Button', 
      { 'Danger': danger }, 
      { 'Caution': caution }, 
      { 'disabled': disabled }
    );
    return (
      <div className={className} onClick={this.handleClick}>
        {this.props.children}
      </div>
    );
  }
}