import React from 'react';
import { TreeManager } from '../../model/data/TreeManager';
import { preventSwipe } from '../../util';
import ChessBoard from '../ChessBoard';
import { AUDIO, playRandomSound } from '../../assets';

const ANIMATION_SPEED = {
  SLOW: 300,
  FAST: 100,
}

/*
<PuzzlePlayer
  tree={puzzleTree}
  onGoodMove={handleGoodMove}
  onBadMove={handleBadMove}
  onSolvedPuzzle={handleSolvedPuzzle}
  size={boardSize}
/>
*/

export default class PuzzlePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animationSpeed: ANIMATION_SPEED.SLOW,
      isAnimating: false,
      lastMove: null,
      treeManager: new TreeManager(),
      reversed: false,
    }

    this._animationFinishedCallback = null;
    document.getElementById('root').addEventListener('touchstart', preventSwipe);
  }

  setAnimationFinishedCallback = (callback) => {
    this._animationFinishedCallback = callback;
  } 

  componentWillUnmount() {
    document.getElementById('root').removeEventListener('touchstart', preventSwipe);
    this._animationFinishedCallback = null;
  }

  getFen() {
    return this.state.treeManager.node.fen;
  }

  isAnimating() {
    return this.state.isAnimating;
  }

  componentDidMount() {
    this.startPuzzle();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.treeManager !== prevProps.treeManager) &&
        (this.props.treeManager.tree !== prevProps.treeManager.tree)) {
      this.startPuzzle();
    }
  }

  startPuzzle() {
    this.setState({
      animationSpeed: ANIMATION_SPEED.SLOW,
      isAnimating: true,
      treeManager: this.props.treeManager,
      reversed: this.props.treeManager.getTurn() === 'b',
    });

    this.setAnimationFinishedCallback(() => {
      this.setState({
        animationSpeed: ANIMATION_SPEED.FAST,
        isAnimating: false,
      });
    });
  }

  handleAnimationFinished = () => {
    if (this._animationFinishedCallback) {
      const callback = this._animationFinishedCallback;
      this._animationFinishedCallback = null;
      callback();
    }
  }

  playSoundEffect(options) {
    if (!this.props.playSounds) {
      return;
    }

    playRandomSound(options);
  }

  handleMove = (move) => {
    if (move.capture) {
      this.playSoundEffect(AUDIO.CAPTURE);
    } else {
      this.playSoundEffect(AUDIO.PIECE);
    }

    const treeManager = this.state.treeManager.maybeMove(move);

    if (!treeManager) {
      this.props.onBadMove(move);
      this.setState({
        lastMove: move,
        treeManager: this.state.treeManager.executeMove(move),
      });
      return;
    }

    // Solved the puzzle
    if (treeManager.isTerminal()) {
      this.props.onSolvedPuzzle(move);
      this.setState({
        lastMove: move,
        treeManager: treeManager,
      });
      return;
    }

    // Played accepted move, now animate the reply
    this.animationSpeed = ANIMATION_SPEED.FAST;
    this.props.onGoodMove(move);

    this.setState({
      lastMove: move,
      treeManager: treeManager,
    });
    
    this.setAnimationFinishedCallback(() => {
      this.setAnimationFinishedCallback(() => {
        this.setState({
          isAnimating: false,
          animationSpeed: ANIMATION_SPEED.FAST,
        });
      });

      this.setState({
        isAnimating: true,
        treeManager: treeManager.moveForward(),
        animationSpeed: ANIMATION_SPEED.SLOW,
      });
    });
  }

  render() {
    return (
      <div className="PuzzlePlayer">
        <ChessBoard 
          fen={this.getFen()}
          lastMove={this.state.lastMove}
          reversed={this.state.reversed}
          draggable={!this.state.isAnimating && this.props.playable}
          size={this.props.size}
          sendMove={this.handleMove}
          animationSpeed={this.state.animationSpeed}
          animateCaptures={false}
          onAnimationFinished={this.handleAnimationFinished}
        />
      </div>
    );
  }
};