import React from 'react';
import classnames from 'classnames';

import Spinner from '../Spinner';
import { RIGHT_BRACKET } from '../../constants';
import './index.css';

export default class DataMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: !!props.collapsed,
    };
  }

  handleToggleCollapse = (e) => {
    e.stopPropagation();
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  renderLoadMore() {
    if (this.props.isLoading) {
      return (
        <div className="Item Spinner"><Spinner/></div>
      );
    }

    if (!this.props.onLoadMore) {
      return;
    }

    return (
      <div className="Item LoadMore">
        <button 
          onClick={this.props.onLoadMore}
          className="Button Action">Load More
        </button>
      </div>
    );
  }

  renderItems() {
    if (!this.props.data) {
      return null;
    }

    const items = this.props.data.map((item, key) => {
      const { label, isSelected } = this.props;
      const classname = classnames('Item', { selected: isSelected && isSelected(item) });
      return (
        <div className={classname} key={key} onClick={() => this.props.onSelect(item)}>
          {label(item)}
        </div>
      );
    });

    return (
      <div className="Items">{items}</div>
    );
  }

  renderCollapseButton() {
    if (!this.props.collapsible) {
      return;
    }

    return (
      <div className="Button Collapse" onClick={this.handleToggleCollapse}>
        {RIGHT_BRACKET}
      </div>
    )
  }

  render() {
    const className = classnames('DataMenu', { collapsed: this.state.collapsed });

    return (
      <div className={className}>
        {this.renderCollapseButton()}
        {this.renderItems()}
        {this.renderLoadMore()}
      </div>
    )
  }
}