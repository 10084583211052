import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { Link } from 'react-router-dom';
import Autoheight from '../Autoheight';
import LoginModal from '../Modal/Login';
import RegistrationModal from '../Modal/Registration';
import SettingsModal from '../Modal/Settings';
import Hamburger from '../Buttons/Hamburger';
import './index.css';
import { logout } from '../../model/api';
import { clearSession } from '../../model/actions';

class AccountMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      menuIsOpen: false,
      loginModalIsOpen: false,
      registerModalIsOpen: false,
      settingsModalIsOpen: false,
    }
  }

  componentDidMount() {
    this.windowListener = window.addEventListener('click', () => {
      this.closeMenu();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowListener);
  }

  closeMenu = () => {
    this.setState({
      menuIsOpen: false,
    });
  }

  toggleMenu = (e) => {
    e.stopPropagation();
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
    });
  }

  renderPuzzlesItem() {
    return (
      <Link to='/puzzles/'>Puzzles</Link>
    );
  }

  renderEngineGameItem() {
    return (
      <Link to='/training-game/'>Training Game</Link>
    )
  }

  renderLoginItem() {
    if (!!this.props.user || this.props.hideSessionLinks) {
      return;
    }

    return (
      <div key='login' onClick={this.showLoginModal}>Log in</div>
    );
  }

  renderRegistrationItem() {
    if (!!this.props.user || this.props.hideSessionLinks) {
      return;
    }

    return (
      <div key='register' onClick={this.showRegistrationModal}>Sign Up</div>
    );
  }

  renderLogoutItem() {
    if (!this.props.user) {
      return;
    }

    return (
      <div key='logout' onClick={this.handleLogout}>Log out</div>
    );
  }

  renderPremiumItem() {
    if (!this.props.user || this.props.user.is_premium || this.props.user.is_staff) {
      return;
    }

    return (
      <Link key='premium' to="/premium/">Upgrade to Premium</Link>
    );
  }

  renderAccountItem() {
    if (!this.props.user) {
      return;
    }

    return (
      <Link key='account' to="/account/">Profile</Link>
    );
  }

  renderAnalysisItem() {
    return (
      <Link key='analysis' to='/analysis/'>Analysis</Link>
    );
  }

  // we could potentially store settings for anonymous users in local storage
  renderSettingsItem() {
    if (!this.props.user) {
      return;
    }

    return (
      <div key='settings' onClick={this.showSettingsModal}>Settings</div>
    );
  }

  renderNewArticleItem() {
    if (!(this.props.user && this.props.user.is_staff)) {
      return;
    }

    return (
      <Link className="Staff" key='new-article' to="/editor/articles/new/">New Article</Link>
    );
  }

  renderNewPuzzleItem() {
    if (!(this.props.user && this.props.user.is_staff)) {
      return;
    }

    return (
      <Link className="Staff" key='new-puzzle' to ='/editor/puzzles/new/'>New Puzzle</Link>
    );
  }

  renderPositionSetupItem() {
    if (!(this.props.user && this.props.user.is_staff)) {
      return;
    }

    return (
      <Link key='position-setup' to="/position-setup/">Position Setup</Link>
    );
  }

  renderTrainingHistoryItem() {
    if (!this.props.user) {
      return;
    }

    return (
      <Link key='training-history' to ='/training-history/'>Training History</Link>
    );
  }

  renderContactItem() {
    return (
      <Link key='contact' to='/contact/'>Contact</Link>
    );
  }

  renderAboutItem() {
    return (
      <Link key='about' to='/about/'>About</Link>
    );
  }

  renderMenuItems = () => {
    if (!this.state.menuIsOpen) {
      return [];
    }

    const links = [
      this.renderPuzzlesItem(),
      this.renderEngineGameItem(),
      //this.renderTrainingHistoryItem(),
      this.renderAnalysisItem(),
      this.renderPositionSetupItem(),
      this.renderLoginItem(),
      this.renderRegistrationItem(),
      this.renderSettingsItem(),
      this.renderAccountItem(),
      //this.renderPremiumItem(),
      this.renderNewArticleItem(),
      this.renderNewPuzzleItem(),
      this.renderLogoutItem(),
      this.renderContactItem(),
      this.renderAboutItem(),
    ];

    return links;
  }

  showSettingsModal = () => {
    this.closeMenu();
    this.setState({
      settingsModalIsOpen: true,
    });
  }

  showLoginModal = () => {
    this.closeMenu();
    this.setState({
      registrationModalIsOpen: false,
      loginModalIsOpen: true,
    });
  }

  showRegistrationModal = () => {
    this.closeMenu();
    this.setState({
      registrationModalIsOpen: true,
      loginModalIsOpen: false,
    });
  }

  onTransitionEnd = (e) => {
    if (e.propertyName === 'top') {
      this.setState({
        shouldNotAnimate: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      loginModalIsOpen: false,
      registrationModalIsOpen: false,
      settingsModalIsOpen: false,
      shouldNotAnimate: false,
    });
  }

  handleLogout = () => {
    logout().then(() => {
      this.props.clearSession();
    });
  }

  render() {
    const menuClassName = classnames(
      'MenuItems', { open: this.state.menuIsOpen },
    )

    return (
      <div className="AccountMenu">
        <Hamburger onClick={this.toggleMenu}/>
        <Autoheight className={menuClassName}>
          {this.renderMenuItems()}
        </Autoheight>
        <LoginModal
          isOpen={this.state.loginModalIsOpen} 
          onRequestClose={this.closeModal}
          onNoAccount={this.showRegistrationModal}
          shouldNotAnimate={this.state.shouldNotAnimate}
          onTransitionEnd={this.onTransitionEnd}
        />
        <RegistrationModal
          isOpen={this.state.registrationModalIsOpen}
          onRequestClose={this.closeModal}
          onHaveAccount={this.showLoginModal}
          shouldNotAnimate={this.state.shouldNotAnimate}
          onTransitionEnd={this.onTransitionEnd}
        />
        <SettingsModal
          isOpen={this.state.settingsModalIsOpen}
          onRequestClose={this.closeModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.session.user,
  hideSessionLinks: state.session.hideSessionLinks,
});

const mapDispatchToProps = {
  clearSession,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);