import {
  ADD_NOTIFICATION,
  DISMISS_NOTIFICATION,
} from '../../constants';

const initialState = [];

export function notifications(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_NOTIFICATION:
      return [...state, { ...payload }];
    case DISMISS_NOTIFICATION:
      const idx = state.indexOf(state.find(n => n.id === payload.id));
      const _state = state.slice();
      _state.splice(idx, 1);
      return _state;
    default:
      return state;
  }
};