import React from 'react';
import { connect } from 'react-redux';

import Modal from '../';
import { CHESS_SETS, CHESSBOARDS, } from '../../../constants';
import { updateSettings } from '../../../model/actions';
import './index.css';

const vals = (obj) => {
  return Object.keys(obj).map(k => obj[k]);
}

const SimpleForm = (props) => {
  const options = props.options.map((option, key) => {
    const { label, value } = option;
    const selected = option.value == props.current;
    const handleChange = () => {
      props.onChange(value);
    }
    return (
      <div className="row" key={key}>
        <label>{label}</label>
        <input type="checkbox" checked={selected} onChange={handleChange}/>
      </div>
    );
  });

  return (
    <div className="SimpleForm">
      <div className="title">{props.title}</div>
      {options}
    </div>
  )
}

class SettingsModal extends React.Component {
  setChessBoard = (chessBoard) => {
    this.props.updateSettings({ chessBoard });
  }

  setChessSet = (chessSet) => {
    this.props.updateSettings({ chessSet });
  }

  renderChessPiecesForm() {
    const current = this.props.settings.chessSet;
    const options = vals(CHESS_SETS).map(name => {
      const label = name[0].toUpperCase() + name.substring(1);
      const value = name;
      return { label, value }
    });
    return (
      <SimpleForm
        title="Chess Pieces"
        options={options}
        onChange={this.setChessSet}
        current={current}
      />
    );
  }

  renderChessBoardsForm() {
    const current = this.props.settings.chessBoard;
    const options = vals(CHESSBOARDS).map(name => {
      const label = name[0].toUpperCase() + name.substring(1);
      const value = name;
      return { label, value }
    });
    return (
      <SimpleForm
        title="Chess Boards"
        options={options}
        onChange={this.setChessBoard}
        current={current}
      />
    );
  }

  render() {
    return (
      <Modal
        className="SettingsModal"
        title="Settings"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
      >
        {this.renderChessPiecesForm()}
        {this.renderChessBoardsForm()}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
});

const mapDispatchToProps = {
  updateSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);