import {
  SET_PUZZLES,
  UPDATE_PUZZLES,
} from '../../constants';

const initialState = [];

export function puzzles(state=initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PUZZLES:
      return payload;
    case UPDATE_PUZZLES:
      return { ...state, ...payload };
    default:
      return state;
  }
};