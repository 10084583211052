export function updatePieces(before, after) {
  const beforeBySquare = {};
  const afterBySquare = {};

  before.forEach(p => beforeBySquare[p.square] = p);
  after.forEach(p => afterBySquare[p.square] = p);

  const result = [];
  const removed = [];
  const added = [];

  before.forEach(p => {
    const _p = afterBySquare[p.square];
    if (!_p) {
      removed.push(p);
      return;
    }

    if (_p.type !== p.type) {
      added.push(_p);
      return;
    }

    result.push(p);
  });

  after.forEach(p => {
    if (!beforeBySquare[p.square]) {
      added.push(p);
    }
  });

  removed.forEach(p => {
    for (let i = added.length-1; i >= 0; i--) {
      const _p = added[i];
      if (p.type === _p.type) {
        result.push(Object.assign({}, p, { square: _p.square }));
        added.splice(i, 1);
        return;
      }
    }
  });

  const pieces = [...result, ...added];
  pieces.sort((a, b) => a.id - b.id);
  // The sort order needs to be stable in order to trigger transition animations.
  return { pieces, removed };
}