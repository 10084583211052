import React from 'react';
import classnames from 'classnames';
import './index.css';

export default class Autoheight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
    this.contentRef = React.createRef();
  }

  componentDidUpdate() {
    setTimeout(this.updateHeight);
  }

  updateHeight = () => {
    const height = this.contentRef.current ? this.contentRef.current.clientHeight : 0;
    if (height !== this.state.height) {
      this.setState({
        height: height,
      });
    }
  }

  render() {
    const style = {
      height: this.state.height + 'px',
    };
    
    const className = classnames('Autoheight', this.props.className);

    return (
      <div {...this.props} className={className} style={style}>
        <div className="content" ref={this.contentRef}>
          {this.props.children}
        </div>
      </div>
    );
  }
}