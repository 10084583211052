import React from 'react';
import Chess from '../../model/data/Chess';
import './index.css';

export default class FenString extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      string: props.fen,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.fen !== prevProps.fen) {
      this.setState({
        string: this.props.fen,
      });
    }
  }

  handleCopy = () => {
    navigator.clipboard.writeText(this.props.fen);
  }

  handleBlur = () => {
    this.setState({
      isEditMode: false,
    });
  }

  handleRequestEditMode = () => {
    if (this.props.editable && this.props.onChange) {
      this.setState({ isEditMode: true });
    }
  }

  handleStringChange = (e) => {
    const string = e.target.value;
    const isValid = new Chess().validate_fen(string);
    if (isValid) { this.props.onChange(string); }
    this.setState({ string });
  }

  render() {
    return (
      <div className="FenString">
        <input
          type="text" 
          readOnly={!this.state.isEditMode}
          value={this.state.string} 
          onChange={this.handleStringChange}
          onClick={this.handleRequestEditMode}
          onBlur={this.handleBlur}
        />
      </div>
    )
  }
}