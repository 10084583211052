import React from 'react';
import ChessBoard from '../ChessBoard';

function parseMarkdown(text) {
  if (!text) {
    return [];
  }

  const paragraphs = text.split(/\n\n+/);
  return paragraphs.map(parseMarkdownParagraph);
}

function parseMarkdownParagraph(p, key) {
  // don't split fen string lines
  if (p.substring(0, 4) === "#FEN") {
    return (
      <ChessBoard size={300} fen={p.substring(5)} key={key}/>
    );
  }

  const words = p.split(/\s+/);

  if (words[0] === "#") {
    return (
      <h1 key={key}>{words.slice(1).join(' ')}</h1>
    );
  }

  if (words[0] === "##") {
    return (
      <h2 key={key}>{words.slice(1).join(' ')}</h2>
    );
  }

  if (words[0] === "###") {
    return (
      <h3 key={key}>{words.slice(1).join(' ')}</h3>
    );
  }

  return (
    <p key={key}>{words.join(' ')}</p>
  );
}

export default class Markdown extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.text !== state.text) {
      return {
        text: props.text,
        content: parseMarkdown(props.text),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      text: null,
      content: null,
    }
  }

  render() {
    return (
      <div className="Markdown">
        {this.state.content}
      </div>
    )
  }
}