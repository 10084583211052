import React from 'react';
import { resetEngine, destroyEngine, parseMultipvInfo } from '../../engine';
import { getSideToMove, convertUciToSan } from '../../util';
import './index.css';

export default class Kibitzer extends React.Component {
  constructor(props) {
    super(props);
    this.engine = null;
    this.state = {
      started: false,
      lines: [],
      multipv: 3,
      nodes: 10000000,
    };
  }

  componentDidMount() {
    if (this.props.auto) {
      this.startEngine();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fen && this.props.fen !== prevProps.fen) {
      this.updateEngine();
    }
  }

  componentWillUnmount() {
    destroyEngine(this.engine);
  }

  stopEngine = () => {
    this.setState({
      started: false,
      lines: [],
    }, () => {
      destroyEngine(this.engine);
    })
  }

  startEngine = () => {
    this.setState({
      started: true,
    }, () => {
      this.updateEngine();
    });
  }

  updateEngine() {
    if (!this.state.started) { return; }
    this.engine = resetEngine('kibitzer');
    this.engine.setListener(this.handleEngineMessage);
    this.engine.sendCommand('setoption name MultiPV value ' + this.state.multipv);
    this.engine.sendCommand('ucinewgame');
    this.engine.sendCommand('position fen ' + this.props.fen);
    this.engine.sendCommand('go nodes ' + this.state.nodes);
  }

  handleEngineMessage = (message) => {
    const data = parseMultipvInfo(message);
    if (!data) {
      return;
    }
    data.san = convertUciToSan(this.props.fen, data.moves);
    const lines = this.state.lines.slice();
    const index = parseInt(data.multipv, 10);
    lines[index] = data;
    this.setState({
      lines,
    });
  }

  renderLines() {
    const lines = this.state.lines.map((line, key) => {
      let { score, mate } = line;
      let text;

      if (mate) {
        text = '#' + mate;
      }

      else {
        if (getSideToMove(this.props.fen) === 'b') {
          score *= -1;
        }
        text = score * 0.01;
      }

      return (
        <div className="Line" key={key}>
          <div className="LineScore">{text}</div>
          <div className="LineMoves">{line.san}</div>
        </div>
      );
    })

    return (
      <div className="Lines">
        {lines}
      </div>
    );
  }

  render() {
    if (!this.state.started) {
      return (
        <div className="Kibitzer"> 
          <div className="Messages"></div>
         {!this.props.auto && <div className="Start Button Action" onClick={this.startEngine}>
            Load Engine
          </div>}
          {this.renderLines()}
        </div>
      );
    }

    return (
      <div className="Kibitzer">
        <div className="Messages">
          {!this.props.auto && <div className="Stop Button" onClick={this.stopEngine}>Unload Engine</div>}
          {this.renderLines()}
        </div>
      </div>
    );
  }
}