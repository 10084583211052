import React from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../../../model/actions';
import * as api from '../../../../model/api';
import Spinner from '../../../Spinner';
import './index.css';

const POLLING_INTERVAL = 1000;

class SubscriptionSuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      succeeded: false,
      retriesExhausted: false,
      retries: 10,
    };
  }

  componentDidMount() {
    setTimeout(this.pollStripeSessionStatus, POLLING_INTERVAL);
  }

  pollStripeSessionStatus = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const sessionId = urlParams.get('session_id');
    api.getStripeSessionStatus(sessionId)
    .then(res => {
      if (res.data.pending) {
        if (this.state.retries > 0) {
          this.setState({
            retries: this.state.retries - 1,
          });
          setTimeout(this.pollStripeSessionStatus, POLLING_INTERVAL);
        } else {
          this.setState({
            retriesExhausted: true,
          });
        }
      } else {
        // update the current user profile if payment was successful
        // to make sure they have full access
        if (res.data.succeeded) {
          this.props.fetchCurrentUser();
        }
        this.setState({
          pending: false,
          succeeded: res.data.succeeded,
        });
      }
    });
  }

  renderContent() {
    if (this.state.pending) {
      return (
        <div className="Content">
          <div className="Copy">Confirming your payment...</div>
          <Spinner/>
        </div>
      );
    }

    if (this.state.succeeded) {
      return (
        <div className="Content">
          <div className="Copy">Payment confirmed!</div>
          <div className="Copy">Your subscription has been updated.</div>
        </div>
      );
    }

    if (!this.state.succeeded) {
      return (
        <div className="Content">
          <div className="Copy">There was a problem processing you payment.</div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="SubscriptionsSuccessPage">
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchCurrentUser,
};

export default connect(null, mapDispatchToProps)(SubscriptionSuccessPage);