import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import NavBar from '../NavBar';
import Notifier from '../Notifier';
import AboutPage from '../Page/About';
import AccountPage from '../Page/Account';
import ActivationPage from '../Page/Activation';
import ArticlePage from '../Page/Article';
import ContactPage from '../Page/Contact';
import EditorPage from '../Page/Editor';
import LandingPage from '../Page/Landing';
import PasswordResetPage from '../Page/PasswordReset';
import PositionSetupPage from '../Page/PositionSetup';
import AnalysisPage from '../Page/Analysis';
import PuzzlesFreestylePage from '../Page/Puzzles/Freestyle';
import PuzzlesPage from '../Page/Puzzles';
import RequireLoginPage from '../Page/RequireLogin'
import SubscriptionsPage from '../Page/Subscriptions';
import SubscriptionsSuccessPage from '../Page/Subscriptions/Success';
import TrainingGamePage from '../Page/TrainingGame';
import TrainingHistoryPage from '../Page/TrainingHistory';
import { STAFF, FREE } from '../../model/roles';
import '../../styles/vars.css';
import './index.css';

/* 
Before going to production need to remove the pages used only for
prototyping and data entry.
https://blog.logrocket.com/multiple-entry-points-in-create-react-app-without-ejecting/
has a good example of how to achieve this using environment variables.
We're already using a flag to set the PWA web worker so this approach should work well here.
*/

class App extends React.Component {
  render() {
    return (
        <div className="App">
          <Switch>
            <Route path="/">
              <NavBar />
              <div className="main-content">
                <Notifier />
                <Switch>
                  <Route
                    path="/about/"
                    exact
                    component={AboutPage}
                  />
                  <Route
                    path="/account/"
                    exact
                    component={AccountPage}
                    notAuthorizedComponent={RequireLoginPage}
                    auth={FREE}
                  />
                  <Route 
                    path="/activate/:uid/:token/" 
                    component={ActivationPage}
                  />
                  <Route
                    path="/article/"
                    exact
                    component={ArticlePage}
                    auth={STAFF}
                  />
                  <Route
                    path="/analysis/"
                    exact
                    component={AnalysisPage}
                  />
                  <Route 
                    path="/contact"
                    exact
                    component={ContactPage}
                  />
                  <Route
                    path="/editor/:type/:id/"
                    exact
                    component={EditorPage}
                    auth={STAFF}
                  />
                  <Route
                    path="/password-reset/:uid/:token/"
                    exact
                    component={PasswordResetPage}
                  />
                  <Route
                    path="/position-setup/"
                    component={PositionSetupPage}
                    auth={STAFF}
                  />
                  {/* <Route 
                    path="/premium/" 
                    exact 
                    component={SubscriptionsPage}
                  />
                  <Route 
                    path="/premium/success/" 
                    component={SubscriptionsSuccessPage}
                  /> */}
                  <Route
                    path="/puzzles/shareable/:puzzleId"
                    exact
                    component={PuzzlesFreestylePage}
                  />
                  <Route
                    path="/puzzles/:filter/"
                    exact
                    // auth={FREE}
                    component={PuzzlesFreestylePage}
                    // notAuthorizedComponent={RequireLoginPage}
                  />
                  <Route
                    path="/puzzles/"
                    exact
                    component={PuzzlesPage}
                  />
                  {/* <Route
                    path="/puzzles/analysis/:id/"
                    exact
                    render={(props) => (
                      <AnalysisPage {...props} resourceType='puzzles'/>
                    )}
                  /> */}
                  <Route
                    path="/training-game/"
                    exact
                    component={TrainingGamePage}
                  />
                  <Route
                    path="/training-history/"
                    exact
                    auth={FREE}
                    component={TrainingHistoryPage}
                    notAuthorizedComponent={RequireLoginPage}
                  />
                  <Route 
                    path="/"
                    component={LandingPage}
                  />
                </Switch>
              </div>
            </Route>
          </Switch>
        </div>
    )
  }
};

export default App;