import React from 'react';
import classnames from 'classnames';
import './index.css';

function Node(props) {
  const { node, ply } = props;
  let text = node.san;

  if (ply %2 === 0) {
    const number = Math.floor(ply/2) + 1;
    text = `${number}. ${text}`;
  }

  if (node.annotation) {
    text = text + node.annotation;
  }

  const selected = (
    node.path && 
    node.path.length && 
    node.path.join(':') === props.currentPath.join(':')
  );

  const onClick = (e) => {
    if (!props.onClick) { return; }
    e.stopPropagation();
    e.preventDefault();
    props.closeContextMenu && props.closeContextMenu();
    props.onClick(node);
  }

  const onContextMenu = (e) => {
    if (!props.onContextMenu) { return; }
    e.stopPropagation();
    e.preventDefault();
    props.onContextMenu(node);
  }

  const textClassName = classnames('Node', 'san', { selected });
  const showContextMenu = props.currentContextMenuNode === node;

  return (
    <span 
      className={textClassName}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      {text}
      {showContextMenu && <props.contextMenuComponent/>}
    </span>
  );
}

function makeIncrementor(n=0) {
  return function() { return n++ }
};

const VariationView = (props) => {
  const { root } = props;
  const dom = [];

  function processNode(node, ply=-1, depth=-1, nextId=makeIncrementor()) {
    if (node.san) {
      dom.push(<Node {...props} key={nextId()} node={node} ply={ply} depth={depth}/>);
    }

    if (node.textAfterMove) {
      dom.push(
        <div className="text" key={nextId()}>{node.textAfterMove}</div>
      );
    }

    for (let i=1; i < node.children.length; i++) {
      dom.push(<span key={nextId()}>(</span>);
      processNode(node.children[i], ply+1, depth+1, nextId);
      dom.push(<span key={nextId()}>)</span>);
    }

    if (node.children.length) {
      processNode(node.children[0], ply+1, depth+1, nextId);
    }
  }

  processNode(root);

  return (
    <div className="VariationView">
      {dom}
    </div>
  );
};

export default VariationView;