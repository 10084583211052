import React from 'react';

import Spinner from '../../Spinner';
import * as api from '../../../model/api';
import './index.css';

export default class HistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    api.getTrainingHistory().then(res => {
      this.setState({
        loading: false,
        succeded: res.data.succeeded,
        failed: res.data.failed,
      });
    });
  }

  renderItems() {
    if (!this.state.failed) {
      return;
    }

    const failedItems = this.state.failed.map((item, key) => {
      return <div key={key} className="Item Incorrect">{item}</div>
    });

    const succededItems = this.state.succeded.map((item, key) => {
      return <div key={key} className="Item Correct">{item}</div>
    }); 

    return (
      <div className="Items">
        <h1>Puzzles that you have yet to pass</h1>
        <div className="Failed">
          {failedItems}
        </div>
        <h1>Puzzles that you have failed in the past</h1>
        <div className="Succeeded">
          {succededItems}
        </div>
      </div>
    );
  }

  renderSucceeded() {

  }

  render() {
    return (
      <div className="TrainingHistoryPage">
        { this.state.loading && <Spinner /> }
        { this.renderItems() }
      </div>
    );
  }
}