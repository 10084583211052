import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { goBack } from 'connected-react-router';
import { titleize, ensureTrailingSlash } from '../../util';
import './index.css';

class Page extends React.Component {
  render() {
    const { url, content="", links=[] } = this.props;
    const navContent = links.map((url, idx) => {
      const title = titleize(url);
      return (
        <Link className="Item" key={idx} to={ensureTrailingSlash(url)}>{title}</Link>
      );
    });

    let nav = null;

    if (navContent.length) {
      nav = (
        <div className="side-nav">
          {navContent}
        </div>
      );
    }

    return (
      <div className="Page">
        {nav}
        <div className="content">
          <div className="inner">
            {content}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  goBack,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);