import React from 'react';
import './'

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <h1>;_; 404 ;_;</h1>
      </div>
    );
  }
}