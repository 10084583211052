import Chess from '../model/data/Chess';

export const convertUciToSan = (fen, uciLine) => {
  if (!uciLine) { return ''; }
  const chess = Chess(fen);
  const out = [];
  const moves = uciLine.split(' ');
  
  moves.forEach(move => {
    const _move = chess.move(move, { sloppy: true });
    out.push(_move.san);
  });

  return out.join(' ');
}