// simple authorization management
export const ANONYMOUS = 'ANONYMOUS';
export const FREE = 'FREE';
export const PREMIUM = 'PREMIUM';
export const STAFF = 'STAFF';

const ROLES = [
  ANONYMOUS,
  FREE,
  PREMIUM,
  STAFF,
];

export const getRoleValue = (role) => {
  return ROLES.indexOf(role);
}

export const getRoleFromUser = (user) => {
  if (!user) { return ANONYMOUS }
  if (user.is_staff) { return STAFF }
  if (user.is_premium) { return PREMIUM }
  return FREE;
}

export const isAuthorized = (user, role) => {
  return getRoleValue(getRoleFromUser(user)) >= getRoleValue(role);
}