import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Chess from '../../model/data/Chess';
import ChessPiece from '../ChessPiece';
import { createPieces, updatePieces } from '../../util';
import PromotionSelector from '../PromotionSelector';
import { RANKS, FILES, CHESSBOARD_COLORS } from '../../constants';
import './index.css';

const DEFAULT_SIZE = 400;

class ChessBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSquare: null,
      pieces: createPieces(new Chess(props.fen).board()),
      promotion: null,
      draggedPiece: null,
      dragStartPosition: null,
      dragOffset: null,
      draggedPieceOffset: null,
      selectedSquare: null,
      selectedPieceColor: null,
      legalMovesFromSelectedSquare: null,
    };

    this.ref = React.createRef();

    this.windowTouchMove = (e) => {
      e.preventDefault();
      const x = e.changedTouches[0].clientX;
      const y = e.changedTouches[0].clientY;
      this.handleHover({ x, y });
      this.handleDrag({ x, y });
    };
    this.windowMouseMove = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      this.handleHover({ x, y });
      this.handleDrag({ x, y });
    };
  }

  componentDidMount() {
    window.addEventListener('touchmove', this.windowTouchMove);
    window.addEventListener('mousemove', this.windowMouseMove);
    window.addEventListener('touchend', this.endDrag);
    window.addEventListener('mouseup', this.endDrag);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.windowTouchMove);
    window.removeEventListener('mousemove', this.windowMouseMove);
    window.removeEventListener('touchend', this.endDrag);
    window.removeEventListener('mouseup', this.endDrag);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fen, settings } = this.props;
    const { promotion } = this.state;
    // Compute minimal changes to piece positions to accomodate next position.
    if (fen !== prevProps.fen) {
      const lastMove = (prevProps.lastMove !== this.props.lastMove) && this.props.lastMove;

      const _pieces = createPieces(new Chess(fen).board());
      const {
        pieces,
      } = updatePieces(this.state.pieces, _pieces);

      // FIXME
      // we can put a phantom piece on the capture square and remove it after a timeout,
      // but need a prop from the parent to be certain that this is the desired behavior.
      // Maybe animate captures? It seems like that should be the default.
      // Maybe setupMode?

      this.setState({
        pieces,
        selectedSquare: null,
        legalMovesFromSelectedSquare: null,
      }, () => {
        if (this.props.onAnimationFinished) {
          setTimeout(
            this.props.onAnimationFinished, 
            this.props.animationSpeed
          );
        }
      });
    }

    // Temporarily move the piece to the promotion square while 
    // the promotion selector is shown.
    if (promotion && !prevState.promotion) {
      const { pieces } = this.state;
      const piece = pieces.filter(p => p.square === promotion.from)[0];
      const idx = pieces.indexOf(piece);
      const _pieces = pieces.slice();
      _pieces.splice(idx, 1);
      this.setState({ 
        pieces: _pieces 
      });
    }
  }

  allowDrag(piece) {
    if (this.props.onDragStart) {
      return this.props.onDragStart(piece);
    }
    const chess = new Chess(this.props.fen);
    if (!chess.moves({square: piece.square}).length) {
      return false;
    }
    return true;
  }

  handleDrag(coords) {
    if (!this.state.draggedPiece) {
      return;
    }

    const { dragStartPosition } = this.state;
    const offsetX = dragStartPosition.x - coords.x;
    const offsetY = dragStartPosition.y - coords.y;

    if (this.state.draggedPiece) {
      this.setState({
        dragOffset: { x: offsetX, y: offsetY },
      });
    }
  }

  beginDrag(piece, coords) {
    this.setState({
      draggedPiece: piece,
      dragStartPosition: coords,
      dragOffset: { x: 0, y: 0 },
    });
  }

  endDrag = () => {
    this.setState({
      draggedPiece: null,
      dragPosition: null,
      draggedPieceOffset: { x: 0, y: 0 },
    });
  }

  isValid(move) {
    const chess = new Chess(this.props.fen);
    return !!chess.move(move);
  }

  isValidPromotion(move) {
    const chess = new Chess(this.props.fen);
    const _move = { ...move, promotion: 'q' };
    return !!chess.move(_move);
  }

  computeSquareFromCoords = (coords) => {
    const { size=this.props.size || DEFAULT_SIZE, reversed } = this.props;
    const squareSize = Math.floor(size / 8);
    const { top, left } = this.ref.current.getBoundingClientRect();
    const x = coords.x - left;
    const y = coords.y - top;
    let file = Math.floor(x/squareSize);
    let rank = Math.floor(y/squareSize);
    const centerX = file*squareSize + squareSize/2;
    const centerY = rank*squareSize + squareSize/2;
    const offsetX = centerX - x;
    const offsetY = centerY - y;
    rank = 7-rank;
    if (reversed) {
      file = 7 - file;
      rank = 7 - rank;
    }
    const square = FILES[file] + RANKS[rank];
    return { square, offset: { x: offsetX, y: offsetY } };
  }

  render() {
    // TODO
    // It feels like this render function is doing way too much work.
    // Find a way to refactor this.

    const { reversed, settings } = this.props;
    const size = this.props.size || DEFAULT_SIZE;
    const squareSize = Math.floor(size / 8);
    const boardStyle = { width: size, height: size };
    const squareStyle = { width: squareSize, height: squareSize };
    const rowStyle = { height: squareSize };

    const classes = ['white square', 'black square'];
    const rows = [];
    const override = CHESSBOARD_COLORS[settings.chessBoard];

    // should only compute this on update
    for (let row = 0; row < 8; row++) {
      const squares = [];
      for (let square = 0; square < 8; square++) {
        const idx = (row + square) % 2;
        const overrideStyle = {};
        if (override) {
          if (idx === 0) {
            overrideStyle.backgroundColor = override.white
          } else {
            overrideStyle.backgroundColor = override.black;
          }
        }
        const baseClassName = classes[idx];
        const id = reversed ?
          FILES[7-square] + RANKS[row] :
          FILES[square] + RANKS[7-row];
        const hovered = id === this.state.currentSquare;
        const selected = id === this.state.selectedSquare;
        if (selected) {
          if (idx === 0) {
            overrideStyle.backgroundColor = 'rgba(255, 255, 255, 0.75)'; 
          } else {
            overrideStyle.backgroundColor = 'rgba(255, 255, 255, 0.5)';
          }
        }
        const className = classnames('square', baseClassName, { hovered });
        squares.push(
          <div
            key={square} 
            className={className}
            style={{...squareStyle, ...overrideStyle}}
          >
          </div>
        );
      }
      rows.push(<div key={row} className="row" style={rowStyle}>{squares}</div>);
    }

    const BOARD_SIZE = squareSize * 7;
    const pieces = this.state.pieces
      .filter(piece => !!piece)
      .map(piece => {
        const rank = RANKS.indexOf(piece.square[1]);
        const _file = FILES.indexOf(piece.square[0])
        const file = this.props.reversed ? 7 - _file : _file;
        const rankOffset = rank * squareSize;
        let left = file * squareSize;

        let top = this.props.reversed ? rankOffset : BOARD_SIZE - rankOffset;
        const dragged = piece === this.state.draggedPiece;

        if (dragged) {
          left -= this.state.dragOffset.x;
          top -= this.state.dragOffset.y;
        }

        return (
          <ChessPiece 
            key={piece.id}
            id={piece.id}
            type={piece.type}
            size={squareSize}
            left={left}
            top={top}
            dragged={dragged}
            chessSet={this.props.settings.chessSet}
            animationSpeed={this.props.animationSpeed}
          />            
        );
    });

    let promotionSelector = null;

    const { promotion } = this.state;

    if (this.state.promotion) {
      const file = promotion.to[0];
      const color = promotion.color;
      const { reversed } = this.props;

      const showAtBottom = (color === 'b' && !reversed) || (color === 'w' && reversed);
      let fileOffset = FILES.indexOf(file);

      if (reversed) {
        fileOffset = 7-fileOffset;
      }
      const left = fileOffset * squareSize;

      let top;
      let bottom;

      if (showAtBottom) {
        bottom = 0;
      } else {
        top = 0;
      }
      
      const onSelect = (type) => {
        this.setState({
          promotion: null,
        });
        this.props.sendMove({
          from: promotion.from,
          to: promotion.to,
          promotion: type,
          capture: promotion.capture,
        });
      }
      promotionSelector = (
        <PromotionSelector
          size={squareSize}
          left={left}
          top={top}
          bottom={bottom}
          color={color}
          handleSelect={onSelect}
          chessSet={this.props.settings.chessSet}
        />
      )
    };

    const dots = [];
    const legalMoves = this.state.legalMovesFromSelectedSquare || {};
    const { selectedPieceColor } = this.state;
    const dotClassName = classnames('Dot', selectedPieceColor);
    
    Object.keys(legalMoves).forEach(square => {
      const width = squareSize / 4;
      const height = squareSize / 4;
      const rank = RANKS.indexOf(square[1]);
      const _file = FILES.indexOf(square[0])
      const file = this.props.reversed ? 7 - _file : _file;
      const rankOffset = rank * squareSize;
      const left = file * squareSize;
      const top = this.props.reversed ? rankOffset : BOARD_SIZE - rankOffset;
      const style = { 
        left: left+(squareSize/4)*3/2, 
        top: top+(squareSize/4)*3/2, 
        width,
        height 
      };
      dots.push(
        <div key={'dot'+square} className={dotClassName} style={style}></div>
      );
    });

    const className = classnames(
      'ChessBoard', { draggable: this.props.draggable }
    );

    const onTouchMove = (e) => {
      e.preventDefault();
    }

    const onTouchStart = (e) => {
      e.preventDefault();
      const x = e.changedTouches[0].clientX;
      const y = e.changedTouches[0].clientY;
      this.handleMouseDown({x, y});
    }

    const onMouseDown = (e) => {
      e.preventDefault();
      const x = e.clientX;
      const y = e.clientY;
      this.handleMouseDown({x, y});
    }

    const onMouseUp = (e) => {
      e.preventDefault();
      const x = e.clientX;
      const y = e.clientY;
      this.handleDrop({x, y});
    }

    const onTouchEnd = (e) => {
      e.preventDefault();
      const x = e.changedTouches[0].clientX;
      const y = e.changedTouches[0].clientY;
      this.handleDrop({x, y});
    }

    return (
      <div 
        className={className} 
        style={boardStyle}
        ref={this.ref}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        {rows}
        {pieces}
        {dots}
        {promotionSelector}
      </div>
    )
  }

  handleMouseDown(coords) {
    if (!this.props.draggable) {
      return;
    }

    const { square, offset } = this.computeSquareFromCoords(coords); 

    // TODO parent component should just set or not set sendSquare
    // no reason for this component to care about the brush prop
    if (this.props.sendSquare && this.props.brush) {
      this.props.sendSquare(square);
      return;
    }

    if (this.state.selectedSquare && square !== this.state.selectedSquare) {
      const legalMovesFromSelectedSquare = this.state.legalMovesFromSelectedSquare || {};
      const isLegal = !!legalMovesFromSelectedSquare[square];
      if (isLegal) {
        this.setState({
          legalMovesFromSelectedSquare: null,
          selectedSquare: null,
        });
        this.handleMove({
          from: this.state.selectedSquare,
          to: square,
        });
        return;
      } 
    }

    const piece = this.state.pieces.filter(p => p.square === square)[0];

    if (!piece) {
      this.setState({
        selectedSquare: null,
        legalMovesFromSelectedSquare: null,
      });
      return;
    }

    if (!this.allowDrag(piece)) {
      return;
    }

    const chess = new Chess(this.props.fen);
    const moves = chess.moves({ square, verbose: true });

    if (moves.length) {
      const legalMovesFromSelectedSquare = {};
      moves.forEach(move => {
        legalMovesFromSelectedSquare[move.to] = true;
      });
      this.beginDrag(piece, coords);
      this.setState({
        draggedPieceOffset: offset,
        selectedSquare: square,
        selectedPieceColor: piece.type[0],
        legalMovesFromSelectedSquare,
      });
    }
  }

  handleHover = (coords) => {
    let { x, y } = coords;
    if (this.state.draggedPieceOffset) {
      x += this.state.draggedPieceOffset.x;
      y += this.state.draggedPieceOffset.y;
    }
    const { square } = this.computeSquareFromCoords({ x, y });
    this.setState({
      currentSquare: square,
    });
  }

  handleMove = (_move) => {
    if (_move.from === _move.to) {
      return;
    }

    const chess = new Chess(this.props.fen);
    const capture = chess.get(_move.to);

    const move = { ..._move, capture };

    if (this.props.allowInvalidMoves || this.isValid(move)) {
      this.props.sendMove(move);
      return;
    }

    const piece = new Chess(this.props.fen).get(move.from);
    if (!piece) {
      return;
    }

    // setting the promotion in state will trigger 
    // the appearance of the promotion selector
    if (this.isValidPromotion(move)) {
      const promotion = {
        ...move,
        color: piece.color,
      };
      this.setState({ promotion });
      return;
    }
  }

  handleDrop = (coords) => {
    if (!this.props.draggable) {
      return;
    }

    const offset = this.state.draggedPieceOffset;
    let { x, y } = coords;
    
    if (offset) {
      x += offset.x;
      y += offset.y;
    }
    
    const { square } = this.computeSquareFromCoords({ x, y });
    const piece = this.state.draggedPiece;

    if (piece == null) { 
      return;
    }

    this.endDrag();
    
    if (piece.square) {
      const move = {
        from: piece.square,
        to: square,
      };

      this.handleMove(move);
    }
  }
};

const mapStateToProps = (state) => ({
  ui: state.ui,
  settings: state.settings,
});

export default connect(mapStateToProps)(ChessBoard);