import React from 'react';
import ChessBoard from '../ChessBoard';
import FenString from '../FenString';
import VariationControls from '../VariationControls';
import VariationView from '../VariationView';
import { TreeManager } from '../../model/data/TreeManager';
import './index.css';
import Kibitzer from '../Kibitzer';

const MakeContextMenuComponent = (handlerMap) => () => {
  const options = Object.keys(handlerMap).map(key => {
    return (
      <div className="ContextMenuAction" onClick={handlerMap[key]}>{key}</div>
    )
  });

  return (
    <div className="ContextMenu">
      {options}
    </div>
  );
}

const TextEditor = (props) => {
  return (
    <div className="TextEditor">
      <div className="Close" onClick={props.requestClose}>&times;</div>
      <textarea value={props.text} onChange={props.onChange}/>
    </div>
  )
}

export default class GameViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treeManager: new TreeManager(props.tree),
      currentContextMenuNode: null,
      textEditorNode: null,
      undo: [],
      redo: [],
    };
    this.ContextMenu = MakeContextMenuComponent({
      "Add Text After Move": this.addTextAfterMove,
      "Delete Text After Move": this.deleteTextAfterMove,
      "Add Annotation": this.addAnnotation,
      "Promote Node": this.promoteNode,
      "Demote Node": this.demoteNode,
      "Delete Node": this.deleteNode,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.tree !== prevProps.tree) {
      this.setState({
        treeManager: new TreeManager(this.props.tree),
        currentContextMenuNode: null,
        textEditorNode: null,
        undo: [],
        redo: [],
      })
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.closeContextMenu);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeContextMenu);
  }

  setTreeManager(treeManager) {
    this.setState({
      treeManager,
      undo: [...this.state.undo, this.state.treeManager],
      redo: [],
    });
    if (this.props.onChange) {
      this.props.onChange(treeManager);
    }
  }

  moveForward = () => {
    this.setTreeManager(this.state.treeManager.moveForward());
  }

  moveBackward = () => {
    this.setTreeManager(this.state.treeManager.moveBackward());
  }

  moveToBeginning = () => {
    this.setTreeManager(this.state.treeManager.moveToBeginning());
  }

  moveToEnd = () => {
    this.setTreeManager(this.state.treeManager.moveToEnd());
  }

  moveToLocation = (node) => {
    this.setTreeManager(this.state.treeManager.moveTo(node.path));
  }

  setTextAfterMove = (node, textAfterMove) => {
    const { treeManager } = this.state;
    this.setTreeManager(treeManager.updateNode(node, { textAfterMove: textAfterMove }));
  }

  handleContextMenu = (node) => {
    this.setState({ currentContextMenuNode: node })
  }

  closeContextMenu = () => {
    this.setState({ currentContextMenuNode: null });
  }

  handleMove = (move) => {
    this.setTreeManager(this.state.treeManager.executeMove(move));
  }

  demoteNode = (e) => {
    e.stopPropagation();
    this.closeContextMenu();
    this.setTreeManager(this.state.treeManager.demoteNode(this.state.currentContextMenuNode));
  }

  promoteNode = (e) => {
    e.stopPropagation();
    this.closeContextMenu();
    this.setTreeManager(this.state.treeManager.promoteNode(this.state.currentContextMenuNode));
  }

  deleteNode = (e) => {
    e.stopPropagation();
    this.closeContextMenu();
    this.setTreeManager(this.state.treeManager.deleteNode(this.state.currentContextMenuNode));
  }

  addTextAfterMove = (e) => {
    e.stopPropagation();
    this.closeContextMenu();
    this.setState({
      textEditorNode: this.state.currentContextMenuNode,
    });
  }

  deleteTextAfterMove = (e) => {
    e.stopPropagation();
    this.closeContextMenu();
    this.setTreeManager(
      this.state.treeManager.updateNode(
        this.state.currentContextMenuNode, 
        node => ({ ...node, textAfterMove: null })
      )
    );
  }

  closeTextEditor = () => {
    this.setState({
      textEditorNode: null,
    });
  }

  handleTextChange = (e) => {
    const { treeManager, textEditorNode } = this.state;
    let _node;
    const _treeManager = treeManager.updateNode(textEditorNode, (node) => {
      _node = node.edit({ textAfterMove: e.target.value });
      return _node;
    });
    this.setTreeManager(_treeManager);
    this.setState({
      textEditorNode: _node,
    });
  }

  handleFenChange = (fen) => {
    this.setTreeManager(TreeManager.fromFen(fen));
  }

  render() {
    const SIZE = this.props.size || 400;
    return (
      <div className="GameViewer">
        <div className="ChessBoardSection">
          <ChessBoard
            fen={this.state.treeManager.node.fen}
            draggable={!!this.props.editable || !!this.props.playable}
            animationSpeed={300}
            sendMove={this.handleMove}
            size={SIZE}
          />
          <VariationControls
            moveForward={this.moveForward}
            moveBackward={this.moveBackward}
            moveToBeginning={this.moveToBeginning}
            moveToEnd={this.moveToEnd}
            style={{ width: `${SIZE}px` }}
          />
          {
            this.props.editable && 
            <FenString 
              editable
              fen={this.state.treeManager.node.fen}
              onChange={this.handleFenChange}
            />
          }
        </div>
        <div className="TextSection">
          { this.props.title && 
            <div className="Title">
              {this.props.title}
            </div>
          }
          <VariationView
            root={this.state.treeManager.tree}
            currentPath={this.state.treeManager.node.path}
            onClick={this.moveToLocation}
            onContextMenu={this.props.editable && this.handleContextMenu}
            currentContextMenuNode={this.state.currentContextMenuNode}
            contextMenuComponent={this.ContextMenu}
            closeContextMenu={this.closeContextMenu}
          />
          { 
          this.props.kibitzer &&
            <Kibitzer fen={this.state.treeManager.node.fen}/>
          }
          {this.props.editable && this.state.textEditorNode && 
            <TextEditor 
              onChange={this.handleTextChange}
              requestClose={this.closeTextEditor}
              text={this.state.textEditorNode.textAfterMove || ""}
            />
          }
        </div>
      </div>
    )
  }  
}