import { SET_SESSION, UPDATE_SESSION } from '../../constants';

const initialState = { 
  user: null,
  token: null,
  promptLogin: false,
  pending: false,
};

export function session(state=initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SESSION:
      return payload;
    case UPDATE_SESSION:
      return { ...state, ...payload };
    default:
      return state;
  }
};