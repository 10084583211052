import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { api } from './api';
import { content } from './content';
import { notifications } from './notifications';
import { puzzles } from './puzzles';
import { session } from './session';
import { settings } from './settings';
import { ui } from './ui';

export const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  api,
  content,
  notifications,
  puzzles,
  session,
  settings,
  ui,
});