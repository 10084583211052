import { CHESS_SETS, CHESS_PIECES, ASSETS } from './assets';
export { CHESS_SETS, CHESS_PIECES, ASSETS };

export const WHITE_WIN = '1-0';
export const DRAW      = '1/2-1/2';
export const BLACK_WIN = '0-1';

export const THEMES = {
  BLUE: 'blue',
  GOLD: 'gold',
};

export const CHESSBOARDS = {
  DEFAULT: 'default',
  WOOD: 'wood',
  CRIMSON: 'crimson',
};

export const CHESSBOARD_COLORS = {
  wood: { black: "rgb(150,110,80)", white: "rgb(231,207,173)" },
  crimson: { black: "rgb(126,37,28)", white: "rgb(207, 198, 179)" },
}

export const PALETTE_PIECES = [
  'wp', 'bp',
  'wn', 'bn',
  'wb', 'bb',
  'wr', 'br',
  'wk', 'bk',
  'wq', 'bq',
];

export const API = {
  DEFAULT: 'DEFAULT',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
}

export const SET_API_STATUS = 'API/SET_STATUS';
export const SET_API_ERROR = 'API/SET_ERROR';
export const SET_API_DATA = 'API/SET_DATA';

// must keep this in sync with vars.css
export const TOP_NAV_HEIGHT = 40;
export const PUZZLE_CONTROLS_MIN_SIZE = 320;

export const LEFT_DOUBLE_BRACKET = '\u00AB';
export const RIGHT_DOUBLE_BRACKET = '\u00BB';
export const LEFT_BRACKET = '\u2039';
export const RIGHT_BRACKET = '\u203A';
export const CARET = '\u25B2';
export const PLUS = '\u002B';

export const FILES = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
export const RANKS = ['1', '2', '3', '4', '5', '6', '7', '8'];
export const PROMOTIONS = ['q', 'r', 'b', 'n'];

export const DRAG_BEGIN = 'DRAG/BEGIN';
export const DRAG_END = 'DRAG/END';
export const SET_BRUSH = 'SET_BRUSH';

export const MOUSE_MOVE = 'UI/MOUSE_MOVE';
export const RESIZE = 'UI/RESIZE';

export const SET_CHESS = 'CHESS/SET'; 
export const UPDATE_CHESS = 'CHESS/UPDATE';

export const SET_SESSION = 'SESSION/CLEAR';
export const UPDATE_SESSION = 'SESSION/UPDATE';

export const SET_CONTENT = 'CONTENT/SET';
export const UPDATE_CONTENT = 'CONTENT/UPDATE';

export const REGISTER = 'REGISTER';
export const SIGN_IN = 'LOGIN';

export const ADD_NOTIFICATION = 'NOTIFICATION/ADD';
export const DISMISS_NOTIFICATION = 'NOTIFICATION/DISMISS';

export const SET_PUZZLES = 'PUZZLES/SET';
export const UPDATE_PUZZLES = 'PUZZLES/UPDATE_PUZZLES';

export const SET_SETTINGS = 'SETTINGS/SET';
export const UPDATE_SETTINGS = 'SETTINGS/UPDATE';

export const SET_ENGINE = 'ENGINE/SET';
export const UPDATE_ENGINE = 'ENGINE/UPDATE';

export const ENGINE = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  READY: 'READY',
}

export const NOTIFICATION_DURATION_MS = 15000;

export const NOTIFICATION_TYPES = {
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  WARN: 'WARN',
};

export const FEN_START_POSITION = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

export const PUZZLE_MODES = {
  standard: {
    type: 'Time Pressure',
    title: 'Standard',
    durationMs: 1000 * 60 * 30,
  },
  rapid: {
    type: 'Time Pressure',
    title: 'Rapid',
    durationMs: 1000 * 60 * 15,
  },
  blitz: {
    type: 'Time Pressure',
    title: 'Blitz',
    durationMs: 1000 * 60 * 5,
  },
  lightning: {
    type: 'Time Pressure',
    title: 'Lightning',
    durationMs: 1000 * 60 * 3,
  },
  sicilian: {
    type: 'Theme',
    title: 'Sicilian',
    params: { eco: 'B20-B99' },
  },
  benoni: {
    type: 'Theme',
    title: 'Benoni',
    params: { eco: 'A00-A99' },
  },
  french: {
    type: 'Theme',
    title: 'French',
    params: { eco: 'C00-C29' },
  }
};