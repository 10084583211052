import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import GameViewer from '../../GameViewer';
import { TreeManager } from '../../../model/data/TreeManager';
import './index.css';
import { api } from '../../../model/reducers/api';

const gameString = 
`1.e4 e5 2.Nf3 d6 3.d4 Bg4 4.dxe5 Bxf3 5.Qxf3 dxe5 6.Bc4 Nf6 7.Qb3 Qe7
8.Nc3 c6 9.Bg5 b5 10.Nxb5 cxb5 11.Bxb5+ Nbd7 12.O-O-O Rd8
13.Rxd7 Rxd7 14.Rd1 Qe6 15.Bxd7+ Nxd7 16.Qb8+ Nxb8 17.Rd8#`;
const gameMoves = gameString.replace(/\d+\./g, '').split(/\s+/);

const title = "Morphy vs The Evil Duke";

let treeManager = new TreeManager();
for (const move of gameMoves) {
  treeManager = treeManager.executeMove(move);
}
treeManager = treeManager.moveToBeginning();

const SCHEMA = {
  puzzles:  { url: '/puzzles/', schema: {} },
  articles: { url: '/articles/', schema: {} },
}

class ArticlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    }
  }

  componentDidMount() {
    this.loadResource();
  }

  loadResource() {
    const { type, id } = this.props;
    const { url, schema } = SCHEMA[type];
    api.getAuthorized(url, { id })
      .then(res => {
        console.log(res);
      });
  }

  toggleEditMode = () => {
    this.setState({
      isEditMode: !this.state.isEditMode,
    });
  }

  renderToggleEditButton() {
    const className = classnames('Button', 'Edit', { Danger: this.state.isEditMode });
    return (
      <div className={className} onClick={this.toggleEditMode}>Edit Mode</div>
    )
  }

  renderEditControls() {
    return (
      <div className="Button Danger Save" onClick={this.handleSave}>Save Changes</div>
    )
  }

  render() {
    return (
      <div className="ArticlePage">
        <GameViewer
          title={title}
          tree={treeManager.tree}
          editable={this.state.isEditMode}
        />
        {this.props.editable && this.renderToggleEditButton()}
        {this.state.isEditMode && this.renderEditControls()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    editable: state.session.user && state.session.user.is_staff,
  };
};

export default connect(mapStateToProps)(ArticlePage);