import { MOUSE_MOVE, RESIZE } from '../../constants';

const initialState = {
  mouse: { x: 0, y: 0 },
  screen: { width: window.innerWidth, height: window.innerHeight },
};

export function ui (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MOUSE_MOVE:
      return { ...state, mouse: payload };
    case RESIZE:
      return { ...state, screen: payload };
    default:
      return state;
  }
};