import React from 'react';
import { connect } from 'react-redux';

import PasswordResetForm from '../../Forms/PasswordReset';
import './index.css';

class PasswordResetPage extends React.Component {
  handleSubmit = (params) => {
  }

  render() {
    return (
      <div className="PasswordResetPage">
        <PasswordResetForm
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);