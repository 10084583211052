import { RANKS, FILES } from '../constants';

let nextId = 1;

export function createPieces(board) {
  const pieces = [];

  for (let rank = 0; rank < 8; rank++) {
    for (let file = 0; file < 8; file++) {
      const piece = board[rank][file];
      if (piece) {
        pieces.push({
          type: piece.color + piece.type,
          square: FILES[file] + RANKS[7-rank],
          id: nextId++,
        });
      }
    }
  };

  return pieces;
}