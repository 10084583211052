import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { createRootReducer } from './reducers';
import { fetchCurrentUser } from './actions';
import { getSessionFromLocalStorage, getSettingsFromLocalStorage } from './localStorage';
import { MOUSE_MOVE, UPDATE_SETTINGS } from '../constants';

export const history = createBrowserHistory()

const ACTION_BLACKLIST = [
  MOUSE_MOVE,
];

const logger = createLogger({
  predicate(_, action) {
    return ACTION_BLACKLIST.indexOf(action.type) === -1;
  }
});

function configureStore(preloadedState) {
  let store;

  console.log(process.env);

  if (process.env.DEBUG) {
    store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      compose(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          thunk,
          logger,
        ),
      ),
    )
  }

  else {
    store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      compose(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          thunk,
        ),
      ),
    )
  }

  return store
};

let _store = null;

// really need some validation logic here to avoid data bugs that
// persist in local storage
export function getStore() {
  if (_store == null) {
    const preloadedState = {};
    const session = getSessionFromLocalStorage();
    if (session) {
      preloadedState.session = session;
    }
    _store = configureStore(preloadedState);
    const settings = getSettingsFromLocalStorage();
    if (settings) {
      _store.dispatch({
        type: UPDATE_SETTINGS,
        payload: settings,
      });
    }
    if (session && session.token) {
      _store.dispatch(fetchCurrentUser());
    }
  }

  return _store;
}

// TODO maybe remove this from prod
window.getStore = getStore;