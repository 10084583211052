import Chess from './Chess';
import { 
  VariationTree, 
  findChild,
  hasChild,
  findChildIndex, 
  updateChildren, 
  updateChildrenAt,
  deserialize,
  serialize,
} from './VariationTree';

// immutable cursor for navigating and editing variation trees

export class TreeManager {
  constructor(tree = new VariationTree(), node = tree, path=[]) {
    this.tree = tree;
    this.node = node;
  }

  static fromFen(fen) {
    return new TreeManager(new VariationTree({ fen }));
  }

  static load(puzzle) {
    return new TreeManager(deserialize(puzzle));
  }

  moveTo(path) {
    let node = this.tree;
    for (let i = 0; i < path.length; i++) {
      const san = path[i];
      node = findChild(node, {san});
      if (!node) { return null; }
    }
    return new TreeManager(this.tree, node);
  }

  moveForward() {
    return this.node.children.length === 0 ?
      this :
      new TreeManager(
        this.tree, 
        this.node.children[0]
      );
  }

  moveBackward() {
    const { path } = this.node;
    return path.length === 0 ? this : this.moveTo(path.slice(0, path.length-1));
  }

  moveToBeginning() {
    return new TreeManager(this.tree);
  }

  moveToEnd() {
    let prev = this;
    let node = prev.moveForward();
    while (prev !== node) {
      prev = node;
      node = prev.moveForward();
    }
    return node;
  }

  /* 
  This move takes a square coordinate object
  while the one below it is a san string.
  We should probably add a canonicalizeMove function somewhere.
  */
  maybeMove(move) {
    const chess = new Chess(this.node.fen);
    const _move = chess.move(move);
    
    if (!_move) {
      return null;
    }

    const { san } = _move;

    if (!hasChild(this.node, { san })) {
      return null;
    }

    return this.executeMove(san);
  }

  executeMove(move) {
    const _move = new Chess(this.node.fen).move(move, { sloppy: true });
    if (!_move) { throw Error('cannot execute invalid move' ); }
    const san = _move.san;
    const existingChild = findChild(this.node, { san });
    
    if (existingChild) {
      return new TreeManager(this.tree, existingChild)
    }

    const chess = new Chess(this.node.fen);
    const output = chess.move(san);
    
    if (!output) {
      return this;
    }

    const child = new VariationTree({
      san: output.san,
      fen: chess.fen(),
      path: [...this.node.path, output.san],
      children: [],
    });

    const tree = updateChildrenAt(this.tree, this.node.path, (children) => {
      return [...children, child];
    });

    return new TreeManager(
      tree,
      child,
    );
  }

  deleteNode(node) {
    if (this.tree.isEmpty()) {
      throw Error('cannot delete root node!');
    }

    const path = node.path.slice(0, node.path.length-1);
    const tree = updateChildrenAt(this.tree, path, (children) => {
      const index = children.indexOf(node);
      const after = [...children.slice(0, index), ...children.slice(index + 1)];
      return after;
    });

    return new TreeManager(tree).moveTo(path);
  }

  updateNode(node, fn) {
    const path = node.path.slice(0, node.path.length-1);
    const tree = updateChildrenAt(this.tree, path, (children) => {
      const index = children.indexOf(node);
      const _children = children.slice();
      _children[index] = fn(node);
      return _children;
    });

    return new TreeManager(tree, this.node);
  }

  promoteNode(node) {
    const path = node.path.slice(0, node.path.length-1);
    const tree = updateChildrenAt(this.tree, path, (children) => {
      const index = children.indexOf(node);
      if (index === 0) { return children; }
      const a = children[index];
      const b = children[index-1];
      const _children = children.slice();
      _children[index] = b;
      _children[index-1] = a;
     return _children;
    });

    return new TreeManager(tree, this.node);
  }

  demoteNode(node) {
    const path = node.path.slice(0, node.path.length-1);
    const tree = updateChildrenAt(this.tree, path, (children) => {
      const index = children.indexOf(node);
      if (index === children.length-1) { return children; }
      const a = children[index];
      const b = children[index+1];
      const _children = children.slice();
      _children[index] = b;
      _children[index+1] = a;
     return _children;
    });

    return new TreeManager(tree, this.node);
  }

  isEmpty() {
    return this.tree.isEmpty();
  }

  isTerminal() {
    return this.node.children.length === 0;
  }

  getTurn() {
    return new Chess(this.node.fen).turn();
  }

  serialize() {
    return serialize(this.tree);
  }
}