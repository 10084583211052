import React from 'react';
import { convertUciToSan } from './convertUciToSan';
import { copyToClipboard } from './copyToClipboard';
import { createPieces } from './createPieces';
import { updatePieces } from './updatePieces';
import { getChild } from './getChild';
import { getSideToMove } from './getSideToMove';
import { preventSwipe } from './preventSwipe';
import { toggleSideToMove } from './toggleSideToMove';

const VALID_EMAIL_REGEX = /[^@]+@[^@]+\.[^@]+/;

export function isValidEmail(string) {
  return VALID_EMAIL_REGEX.test(string)
}

export function getMembershipType(user) {
  if (!user) { return 'Anonymous'; }
  if (user.is_staff) { return 'Admin'; }
  if (user.is_premium) { return 'Premium'; }
  return 'Free';
}

export function getUserDetails(user) {
  if (!user) {
    return {
      email: null,
      membershipType: 'free',
    }
  }

  return {
    ...user,
    membershipType: getMembershipType(user),
  };
}

// convert snake cased url to title case
export function titleize(string) {
  const segs = string.split('-');
  const words = segs.map(seg => {
    if (!seg) { return seg; }
    const x = seg[0].toUpperCase();
    const xs = seg.substring(1);
    return x + xs;
  });
  return words.join(' ');
}

// ensure url ends with a trailing slash
export function ensureTrailingSlash(url) {
  return (url[url.length-1] !== '/') ? url + '/' : url;
}

export function processErrorMessages(meta) {
  const errors = meta.error || meta.submitError;
  if (errors && meta.touched) {
    return (
      <ul className="errors">
        {errors.map((err, key) => (
          <li key={key}>{err}</li>
        ))}
      </ul>
    );
  }
}

export { 
  convertUciToSan,
  copyToClipboard,
  createPieces, 
  getChild,
  getSideToMove,
  preventSwipe,
  toggleSideToMove,
  updatePieces, 
};