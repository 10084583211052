import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { dismissNotification } from '../../model/actions';
import Autoheight from '../Autoheight';
import './index.css';

const Notification = (props) => {
  const className = classnames('Notification', props.type);
  return (
    <div className={className}>
      {props.content}
      <div className="Dismiss" onClick={() => props.handleDismiss(props.id)}>&times;</div>
    </div>
  );
}

class Notifier extends React.Component {
  handleDismiss = (id) => {
    this.props.dismissNotification(id);
  }

  render() {
    const notifications = this.props.notifications.map((data, key) => {
      return <Notification {...data} handleDismiss={this.handleDismiss} key={key} />
    });

    return (
      <Autoheight className="Notifier">{notifications}</Autoheight>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications
  };
}

const mapDispatchToProps = {
  dismissNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);