import React from 'react';
import { connect } from 'react-redux';
import ChessPiece from '../ChessPiece';
import { PROMOTIONS } from '../../constants';
import './index.css';

function PromotionSelector(props) {
  function makeClickHandlerFor(piece) {
    return function(e) {
      e.stopPropagation();
      e.preventDefault();
      props.handleSelect(piece);
    }
  }

  const style = {
    left: props.left,
    top: props.top,
    bottom: props.bottom,
    width: props.size,
    height: props.size * 4,
  };

  const pieces = PROMOTIONS.map((piece, index) => {
    const type = props.color + piece;
    let top;
    let bottom;
    
    console.log({
      piece, index, chessSet: props.chessSet, type,
    });

    if (props.top != null) { 
      top = props.top + index * props.size; 
    }
    if (props.bottom != null) { 
      bottom = props.bottom + index * props.size; 
    }

    const handler = makeClickHandlerFor(piece);

    return (
      <ChessPiece
        chessSet={props.chessSet}
        key={type}
        type={type}
        top={top}
        bottom={bottom}
        size={props.size}
        onClick={handler}
        onTouchStart={handler}
      />
    );
  });

  return (
    <div className="PromotionSelector" style={style}>{pieces}</div>
  )
}

const mapStateToProps = (state) => {
  return {
    chessSet: state.settings.chessSet,
  }
};

export default connect(mapStateToProps)(PromotionSelector);