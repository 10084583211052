import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './index.css';

function Card(props) {
  const className = classnames('Card', props.className);

  return (
    <div className={className}>
      {props.children}
    </div>
  );
}

function PuzzleButton(props) {
  const { title, subtext, onClick } = props;
  const url = `/puzzles/${props.url || title.toLowerCase()}/`;

  return (
    <Link to={url} className="PuzzleButton Button Action" onClick={onClick}>
      {title}
    </Link>
  );
}

export default class PuzzlesPage extends React.Component {
  requireActiveUser = () => {
  }

  render() {
    return (
      <div className="PuzzlesPage">
        <div className="Content">
          <div className="Time Column">
            <Card className="Title">Thematic Puzzles</Card>
            <div className="Buttons">
              <PuzzleButton
                title="Freestyle"
              />
              <PuzzleButton
                title="Spanish"
              />
              <PuzzleButton
                title="French"
              />
              <PuzzleButton
                title="Najdorf"
              />
              <PuzzleButton
                title="Dragon"
              />
              <PuzzleButton
                title="Grunfeld"
              />
              <PuzzleButton
                title="Queen's Gambit"
                url="queens-gambit"
              />
            </div>
          </div>
          {/* <div className="Theme Column">
            <Card className="Title">Thematic Puzzles</Card>
            <PuzzleButton 
              title="French"
            />
            <PuzzleButton
              title="Sicilian"
            />
            <PuzzleButton
              title='Benoni'
            />
            <PuzzleButton
              title="Queen's Gambit"
              url="queens-gambit"
            />
          </div> */}
        </div>
        {/* <div className="Leaderboard">
          <Card className="Title">Leaderboard</Card>
        </div> */}
      </div>
    );
  }
}