import { SET_CONTENT, UPDATE_CONTENT } from '../../constants';

const initialState = {};

export function content(state=initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CONTENT:
      return payload;
    case UPDATE_CONTENT:
      return { ...state, ...payload };
    default:
      return state;
  }
};