import React from 'react';
import './index.css';

const VariationControls = (props) => (
  <div className="VariationControls" style={props.style} >
    <div className="Button" onClick={props.moveToBeginning}>{"<<"}</div>
    <div className="Button" onClick={props.moveBackward}>{"<"}</div>
    <div className="Button" onClick={props.moveForward}>{">"}</div>
    <div className="Button" onClick={props.moveToEnd}>{">>"}</div>
  </div>
);

export default VariationControls;