import React from 'react';
import classnames from 'classnames';
import './index.css';

const BOOLEAN = 'BOOLEAN';
const STRING = 'STRING';
const NUMBER = 'NUMBER';

export default class AutoForm extends React.Component {
  getTypeFor(key) {
    const value = this.props.data[key];
    
    if (typeof value === 'boolean') {
      return BOOLEAN;
    }

    if (typeof value === 'string') {
      return STRING;
    }

    if (typeof value === 'number') {
      return NUMBER;
    }

    return null;
  }

  renderCheckBoxFor(key) {
    const handler = this.makeChangeHandlerFor(key);
    const onChange = (e) => {
      handler(!!e.target.checked);
    }

    return (
      <div className="row" key={key}>
        <label>{key}</label>
        <input 
          type="checkbox" 
          checked={this.props.data[key]}
          onChange={onChange}
        />
      </div>
    );
  }

  renderTextInputFor(key) {
    const handler = this.makeChangeHandlerFor(key);
    const onChange = (e) => {
      handler(e.target.value);
    }
    return (
      <div className="row" key={key}>
        <label>{key}</label>
        <input
          type="text"
          value={this.props.data[key]}
          onChange={onChange}
        />
      </div>
    )
  }

  renderNumericInputFor(key) {
    const handler = this.makeChangeHandlerFor(key);
    const onChange = (e) => {
      const str = e.target.value;
      try {
        const number = JSON.parse(str);
        if (typeof number === 'number') {
          handler(number);
        }
      } catch (e) {
        return;
      }
    }
    return (
      <div className="row" key={key}>
        <label>{key}</label>
        <input
          type="text"
          value={this.props.data[key]}
          onChange={onChange}
        />
      </div>
    )
  }

  makeChangeHandlerFor(key) {
    const handleChange = (value) => {
      const data = {
        ...this.props.data,
        [key]: value,
      };
      this.props.onChange(data);
    };
    return handleChange;
  }

  renderField(key) {
    const type = this.getTypeFor(key);
    
    if (type === BOOLEAN) {
      return this.renderCheckBoxFor(key);
    }

    if (type === STRING) {
      return this.renderTextInputFor(key);
    }

    if (type === NUMBER) {
      return this.renderNumericInputFor(key);
    }

    return;
  }

  getKeys() {
    const data = this.props.data || {};
    let keys = Object.keys(data);
    if (this.props.only) {
      keys = keys.filter(k => this.props.only.indexOf(k) !== -1);
    }
    if (this.props.exclude) {
      keys = keys.filter(k => this.props.exclude.indexOf(k) === -1);
    }
    return keys;
  }

  render() {
    const content = this.getKeys().map(key => this.renderField(key));
    const className = classnames('AutoForm', { empty: content.length === 0 });
    return (
      <div className={className}>
        {content}
      </div>
    )
  }
}