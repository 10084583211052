import React from 'react';
import { connect } from 'react-redux';
import {loadStripe} from '@stripe/stripe-js/pure';
import * as api from '../../../model/api';
import './index.css';

const PRODUCTS = [
  { id: 0, duration: "1 month", price: "$5.99" },
  { id: 1, duration: "1 year", price: "$49.99" },
];

const Product = (props) => {
  const { product, checkout } = props;
  const { id, duration, price } = product;

  return (
    <div className="Product Button Action"
      onClick={ () => checkout(id) }
    >
      {`${duration} for ${price}`}
    </div>
  );
}

const ProductsList = (props) => {
  const { products, checkout } = props;
  const content = products.map(product => {
    return (
      <Product 
        product={product}
        checkout={checkout}
      />
    );
  });
    
  return (
    <div className="Products">
      {content}
    </div>
  );
};

const ProductsContent = (props) => {
  return (
    <div className="ProductsContent">
      <div className="Header">Take Your Chess to the Next Level With a Premium Membership</div>
      <ul className="Features">
        <li>Unlimited puzzles</li>
        <li>Personalized training content</li>
        <li>Set training goals and reach them using spaced repetition</li>
        <li>Support the continuing development of chesstechnique.com</li>
      </ul>
      <ProductsList products={PRODUCTS} checkout={props.checkout}/>
    </div>
  );
}

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripePromise: null,
    };
  }

  componentDidMount() {
    this.setState({
      stripePromise: api.getStripeConfig()
        .then(res => {
          return loadStripe(res.data.publicKey);
        }),
    })
  }

  checkout = (id) => {
    api.getStripeSession(id)
      .then(res => {
        const { sessionId } = res.data;
        const stripePayload = { sessionId };
        this.state.stripePromise
          .then(stripe => stripe.redirectToCheckout(stripePayload));
      });
  }

  render() {
    return (
      <div className="ProductsPage">
        <ProductsContent 
          products={PRODUCTS}
          checkout={this.checkout}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user
});

export default connect(mapStateToProps)(ProductsPage);