import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import RegistrationForm from '../../Forms/Registration';
import Modal from '../';
import { register } from '../../../model/api';
import { notify } from '../../../model/actions';
import './index.css';

class RegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      keepMeLoggedIn: true,
      errors: null,
      shake: false,
    };
    this.modalRef = React.createRef();
  }

  handleEmailChange = email => {
    this.setState({
      email,
    });
  };

  handlePasswordChange = password => {
    this.setState({
      password,
    });
  }

  shake() {
    this.setState({ shake: true }, () => {
      setTimeout(() => {
        this.setState({ shake: false });
      }, 1000);
    });
  }

  handleRegistration = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const params = { email, password };
    return register(params)
      .then((res) => {
        this.props.onRequestClose();
        this.props.notify('Activation email sent! Check your inbox for an activation link!')
        return res;
      })
      .catch(error => {
        if (error.response && error.response.data) {
          this.shake();
          this.setState({
            errors: error.response.data,
          });
          return Promise.resolve({
            ...error.response.data,
          });
        } else {
          // FIXME need to potentially handle network error here
          return Promise.reject(error);
        }
      });
  }

  render() {
    const loginLink = (
      <div className="LoginLink" onClick={this.props.onHaveAccount}>
        Already have an account?
      </div>
    )

    const className = classnames({ 
      static: this.props.shouldNotAnimate,
      shake: this.state.shake,
    });

    return (
      <Modal
        ref={this.modalRef}
        title="Register"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        className={className}
        errors={this.state.errors}
      >
        <RegistrationForm 
          onSubmit={this.handleRegistration}
          onEmailChange={this.handleEmailChange}
          onPasswordChange={this.handlePasswordChange}
          email={this.state.email}
          password={this.state.password}
          errors={this.state.errors}
        />
        {loginLink}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  notify,
}

export default connect(null, mapDispatchToProps)(RegistrationModal);