import React from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';
import { TOP_NAV_HEIGHT } from '../../../constants';
import { TreeManager } from '../../../model/data/TreeManager';
import { resetEngine, destroyEngine } from '../../../engine';
import Chessboard from '../../ChessBoard';
import Spinner from '../../Spinner';
import './index.css';
import VariationView from '../../VariationView';
import Chess from '../../../model/data/Chess';

const DEPTH = 16;
const CONTROLS_MIN_WIDTH = 300;

const MODE = {
  PAUSED: 'PAUSED',
  PLAYING: 'PLAYING',
  GAME_OVER: 'GAME_OVER',
};

const ANIMATION_SPEED = {
  SLOW: 300,
  FAST: 50,
};

const MENU_ITEMS = [
  { 
    name: 'Start Position',
    fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  },
  {
    name: 'Queenless',
    fen: 'rnb1kbnr/pppppppp/8/8/8/8/PPPPPPPP/RNB1KBNR w KQkq - 0 1',
  },
  {
    name: 'Pawn Odds',
    fen: 'rnbqkbnr/ppppp1pp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  },
  {
    name: 'Pawn Odds Rook Endgame',
    fen: 'r3k2r/ppp1pppp/8/8/8/8/PPPPPPPP/R3K2R w KQkq - 0 1',
  },
  {
    name: 'Lucena Position',
    fen: '6K1/4k1P1/8/8/8/8/6r1/7R w - - 0 1',
  },
  {
    name: 'Bishop and Knight vs. King',
    fen: '8/8/8/8/3k4/8/8/3NKB2 w - - 0 1',
  },
  {
    name: 'Two Bishops vs. King',
    fen: '8/8/8/8/8/5k2/8/4KBB1 w - - 0 1',
  },
];

export class TrainingGamePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: MODE.PAUSED,
      playerColor: 'w',
      showIntroModal: true,
      treeManager: new TreeManager(),
      title: 'New Training Game',
      isAnimating: false,
      animationSpeed: ANIMATION_SPEED.FAST,
      gameOverCondition: null,
    };
    this.engine = null;
  }

  componentWillUnmount() {
    destroyEngine(this.engine);
  }

  handleSelectMenuItem = (item) => {
    this.setState({
      treeManager: TreeManager.fromFen(item.fen),
      gameOverCondition: null,  
    });
  }

  toggleMode = () => {
    const mode = (this.state.mode === MODE.PLAYING || this.state.mode === MODE.GAME_OVER) ? 
      MODE.PAUSED : 
      MODE.PLAYING;

    this.setState({
      mode,
    });
  }

  setPlaying = () => {
    this.setState({
      mode: MODE.PLAYING,
    });
  }

  setPaused = () => {
    this.setState({
      mode: MODE.PAUSED,
    });
  }

  setGameOver = () => {
    this.setState({
      mode: MODE.GAME_OVER,
    });
  }

  getDesktopLayout() {
    const padding = 16;
    const { width, height } = this.props.screen;
    const boardMaxSize = Math.min(width - CONTROLS_MIN_WIDTH - padding, height - TOP_NAV_HEIGHT) - padding * 2;
    const squareSize = Math.floor(boardMaxSize / 8);
    const boardSize = squareSize * 8;
    let controlsWidth = Math.min(width-boardSize-padding*3);

    const controlsStyle = {
      position: 'absolute',
      top: padding,
      left: boardSize + padding*2,
      width: controlsWidth,
      height: boardSize,
    };

    return {
      boardSize,
      controlsStyle,
      containerPadding: Math.floor((width - (boardSize + controlsWidth + padding)) / 2),
    };
  }

  getMobileLayout() {
    const { width, height } = this.props.screen;
    const padding = 16;
    const boardMaxSize = Math.min(width - 24, height);
    const squareSize = Math.floor(boardMaxSize / 8);
    const boardSize = squareSize * 8;

    const controlsStyle = {
      height: height - (boardSize + padding),
    };

    return {
      boardSize,
      controlsStyle,
    };
  }

  getLayout() {
    const { width } = this.props.screen;
    return width <= 600 ? this.getMobileLayout() : this.getDesktopLayout();
  }

  renderPlayPauseButton() {
    let text;
    switch (this.state.mode) {
      case MODE.PLAYING: text = 'Pause'; break;
      case MODE.PAUSED: text = 'Play'; break;
      case MODE.GAME_OVER: text = 'New Game'; break;
    }
    const disabled = this.state.mode === MODE.GAME_OVER;
    return (
      <div 
        className="Button Action PlayPauseButton" 
        onClick={this.toggleMode}
      >
        {text}
      </div>
    );
  }

  renderMenu() {
    const items = MENU_ITEMS.map((item, key) => {
      const className = classnames(
        "MenuItem", 
        { selected: item.fen === this.state.treeManager.tree.fen}
      );
      return (
        <div 
          className={className} 
          key={key} 
          onClick={() => this.handleSelectMenuItem(item)}
        >
          {item.name}
        </div>
      )
    });

    const className = classnames('Menu', { hidden: this.state.mode !== MODE.PAUSED });

    return (
      <div className={className}>
        {items}
      </div>
    );
  }

  updateEngine(fen) {
    this.engine = resetEngine('training-game');
    this.engine.setListener(this.handleEngineMessage);
    this.engine.sendCommand('ucinewgame');
    this.engine.sendCommand('position fen ' + fen);
    this.engine.sendCommand('go depth ' + DEPTH);
  }

  handleEngineMessage = (message) => {
    if (!/bestmove/.test(message)) {
      return;
    }
    const move = message.match(/([a-h][1-9][a-h][1-9])/)[0];
    this.handleEngineMove(move);
  }

  handleMove = (move) => {
    const treeManager = this.state.treeManager.executeMove(move);
    const chess = Chess(treeManager.node.fen);

    if (chess.game_over()) {
      let gameOverCondition;

      if (chess.in_checkmate()) {
        gameOverCondition = 'CHECKMATE';
      } else if (chess.in_stalemate()) {
        gameOverCondition = 'STALEMATE';
      }

      this.setState({
        treeManager,
        isAnimating: false,
        mode: MODE.GAME_OVER,
        gameOverCondition,
        ANIMATION_SPEED: ANIMATION_SPEED.SLOW,
      });
    } else {
      this.setState({
        treeManager,
        isAnimating: true,
        mode: MODE.PLAYING,
        gameOverCondition: null,
        animationSpeed: ANIMATION_SPEED.SLOW,
      });
      
      this.updateEngine(treeManager.node.fen);
    }
  }

  handleEngineMove = (move) => {
    const { treeManager } = this.state;
    const nextTreeManager = treeManager.executeMove(move);
    this.setState({
      treeManager: nextTreeManager,
    });
    this.animationFinishedCallback = () => {
      this.setState({
        isAnimating: false,
        animationSpeed: ANIMATION_SPEED.FAST,
      });
    }
  }

  handleAnimationFinished = () => {
    if (this.animationFinishedCallback) {
      this.animationFinishedCallback();
    }
    this.animationFinishedCallback = null;
  }

  render() {
    const { boardSize, controlsStyle } = this.getLayout();

    return (
      <div className="TrainingGamePage">
        <Chessboard
          size={boardSize}
          draggable={!this.state.isAnimating}
          fen={this.state.treeManager.node.fen}
          sendMove={this.handleMove}
          animationSpeed={this.state.animationSpeed}
          onAnimationFinished={this.handleAnimationFinished}
        />
        <div className="Controls" style={controlsStyle}>
          {this.renderPlayPauseButton()}
          {this.renderMenu()}
          <VariationView 
            root={this.state.treeManager.tree}
            currentPath={this.state.treeManager.node.path}
          />
          {this.state.isAnimating && <Spinner className="light"/>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    screen: state.ui.screen,
  });
};

export default connect(mapStateToProps)(TrainingGamePage);