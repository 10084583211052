import axios from 'axios';
import { getStore } from '../../model';

const { hostname, protocol } = window.location;
const port = window.location.port ? ":" + window.location.port : "";

const apiPath = (path) => {
  return `${protocol}//${hostname}${port}/api${path}`;
}

const getToken = () => {
 return getStore().getState().session.token;
}

const getHeaders = () => {
  const token = getToken();
  if (!token) {
    return {};
  }
  return { headers: {
    'Authorization': `Token ${token}`
  }};
}

export const post = (path, params) => {  
  const url = apiPath(path);
  return axios.post(url, params);
}

export const postAuthorized = (path, params) => {
  const url = apiPath(path);
  return axios.post(url, params, getHeaders());
}

export const get = (path, params={}) => {
  const url = apiPath(path);
  return axios.get(url, {params});
}

export const del = (path) => {
  const url = apiPath(path);
  return axios.delete(url);
}

export const put = (path, params) => {
  const url = apiPath(path);
  return axios.put(url, params);
}

export const getAuthorized = (path, params={}) => {
  const url = apiPath(path);
  return axios.get(url, { params: {...params}, ...getHeaders() });
}

export const putAuthorized = (path, params) => {
  const url = apiPath(path);
  return axios.put(url, params, getHeaders());
}

export const register = (params) => {
  const path = '/auth/users/';
  return post(path, params);
};

export const login = (params) => {
  const path = '/auth/token/login/';
  return post(path, params);
}

export const logout = (params) => {
  const path = '/auth/token/logout/';
  return postAuthorized(path, params);
}

export const activate = (params) => {
  const path = '/auth/users/activation/';
  return post(path, params);
}

export const getPuzzles = (params={}) => {
  const path = '/client-puzzles/';
  return getAuthorized(path, params);
}

export const getPuzzle = (params) => {
  const path = `/client-puzzles/${params.id}/`;
  return get(path);
}

export const savePuzzle = (params) => {
  if ('id' in params) {
    const path = `/puzzles/${params.id}/`;
    return putAuthorized(path, params);
  } else {
    const path = '/puzzles/';
    return postAuthorized(path, params);
  }
}

export const deletePuzzle = (id) => {
  const path = `/puzzles/${id}/`;
  return del(path);
}

export const getGames = () => {
  const path = '/games/';
  return get(path);
}

export const getGame = (params) => {
  const path = `/games/${params.id}`;
  return get(path);
}

export const saveGame = (params) => {
  if ('id' in params) {
    const path = `/games/${params.id}/`;
    return put(path, params);
  } else {
    const path = '/games/';
    return post(path, params);
  }
}

export const deleteGame = (id) => {
  const path = `/games/${id}/`;
  return del(path);
}

export const getCandidates = (params={}) => {
  const path = '/candidates/';
  return get(path, params);
}

export const me = () => {
  const path = '/auth/users/me/';
  return getAuthorized(path);
}

export const getContent = (resource) => {
  const path = `/content/${resource}`;
  return get(path);
}

export const getStripeConfig = () => {
  const path ='/payments/stripe-config/';
  return getAuthorized(path);
}

export const getStripeSession = (id) => {
  const path = `/payments/stripe-session/${id}/`;
  return getAuthorized(path);
}

export const getStripeSessionStatus = (id) => {
  const path = `/payments/stripe-session-status/${id}/`;
  return getAuthorized(path);
}

export const sendInteraction = (data) => {
  const path = `/interactions/`;
  return postAuthorized(path, data);
}

export const createTimeTraining = (data) => {
  const path = `/time-training/`;
  return postAuthorized(path, data);
}

export const getTimeTrainingHistory = (offset=0) => {
  const path = `/time-training/`;
  return getAuthorized(path, { params: { offset } });
}

/*
This endpoint expects a space separated list of integers in
the puzzle_ids query parameter to ensure session unique puzzles are returned
*/
export const getTimeTrainingPuzzles = (puzzleIds=[], difficulty=0) => {
  const params = {
    puzzle_ids: puzzleIds.join(' '),
    difficulty,
  };
  const path = '/time-training-puzzles/';
  return getAuthorized(path, params);
}

export const getTrainingHistory = () => {
  const path = '/training-history/';
  return getAuthorized(path);
}

export const resetPassword = (data) => {
  const path = `/auth/users/reset_password/`;
  return post(path, data);
}

export const sendFeedback = (data) => {
  const path = `/feedback/`;
  return postAuthorized(path, data);
}

window.api = {
  getTrainingHistory,
  getTimeTrainingHistory,
  getContent,
  getStripeConfig,
  getTimeTrainingPuzzles,
};