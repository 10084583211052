import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as api from '../../../model/api';
import { notify } from '../../../model/actions';
import './index.css';

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: '',
    }
  }

  handleFeedbackChange = (e) => {
    this.setState({
      feedback: e.target.value,
    });
  }

  handleSendFeedback = () => {
    api.sendFeedback({ text: this.state.feedback });
    this.props.notify('Message sent! Someone from our staff will review your message soon!');
    this.props.push('/');
  }

  render() {
    let copy = 'Please send any questions or comments to andrew@chesstechnique.com';
    let formSection;

    if (this.props.user) {
      copy = copy + ', or use the form below'
    
      formSection = (
        <div className="FormSection">
          <textarea 
            maxLength={1000}
            value={this.state.feedback} 
            onChange={this.handleFeedbackChange}
          />
          <button className="Button Action" onClick={this.handleSendFeedback}>Send</button>
        </div>
      );
    }

    return (
      <div className="ContactPage">
        <div className="Copy">
          {copy}
        </div>
        {formSection}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.session.user,
  }
};

const mapDispatchToProps = {
  notify,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);