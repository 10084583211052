import React from 'react';

import classnames from 'classnames';
import './index.css';

export default class Spinner extends React.Component {
  render() {
    const className = classnames('SpinnerCircle', this.props.className);

    const style = {
      ...this.props.styles,
    }

    return (
      <div className={className} style={style}>
        <div className="Spinner"></div>
      </div>
    );
  }
}