import React from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import Page from '..';
import { notify, promptLogin } from '../../../model/actions';
import { activate } from '../../../model/api';
import { NOTIFICATION_TYPES } from '../../../constants';

class ActivationPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.performActivation();
  }

  performActivation() {
    const { uid, token } = this.props.match.params;
    activate({ uid, token })
      .then(res => {
        this.props.notify(
          'Your account has been activated!',
          NOTIFICATION_TYPES.SUCCESS
        );
        this.props.replace('/');
        this.props.promptLogin();
      })
      .catch(err => {
        this.props.notify(
          'Unable to activate account.',
          NOTIFICATION_TYPES.WARN
        );
        this.props.replace('/');
        this.props.promptLogin();
      })
  }

  render() {
    const content = (
      <div className="ActivationPage">
      </div>
    );

    const links = [];

    return (
      <Page content={content} links={links}/>
    );
  }
}

const mapDispatchToProps = {
  notify,
  push,
  replace,
  promptLogin,
};

export default connect(null, mapDispatchToProps)(ActivationPage);