import { getStore } from '..';
import * as api from '.';
import { 
  SET_API_STATUS, 
  SET_API_ERROR, 
  SET_API_DATA,
  API, 
} from '../../constants';

export const registry = {};

export function defineApi(config) {
  Object.keys(config).forEach(key => {
    const _config = { key, ...config[key] };
    createEndpoint(_config);
  });

  return registry;
}

export function getInitialState() {
  const state = {};
  Object.keys(registry).forEach(key => {
    state[key] = {
      status: API.DEFAULT,
      results: [],
      offset: 0,
      count: 0,
      promise: null,
      error: null,
    }
  });
  return state;
}

const makeFetchList = (config) => () => {
  const store = getStore();
  const { path, key, authorize } = config;
  const state = store.getState();
  const entry = state.api[key];
  const fetcher = authorize ? api.getAuthorized : api.get;

  const params = { ...config.params };
  if (entry.offset) { params.offset = entry.offset; }
  if (entry.limit) { params.limit = entry.limit; }

  const promise = fetcher(path, params)
    .then(response => {
      store.dispatch({
        type: SET_API_DATA,
        payload: {
          key,
          count: response.data.count,
          results: response.data.results,
          status: API.DEFAULT,
        }
      });
    })
    .catch(err => {
      store.dispatch({
        type: SET_API_ERROR,
        payload: {
          key,
          error: err,
          status: API.ERROR,
        }
      })
    });

  store.dispatch({ 
    type: SET_API_STATUS,
    payload: {
      key,
      status: API.PENDING,
      promise: promise,
    },
  });
}

const makeFetchOne = (config) => {};
const makeUpdateOne = (config) => {};
const makeDeleteOne = (config) => {};

export function createEndpoint(config) {
  const fns = {
    list: makeFetchList(config),
    create: makeFetchOne(config),
    update: makeUpdateOne(config),
    delete: makeDeleteOne(config),
  };

  registry[config.key] = {
    config,
    ...fns,
  };
}