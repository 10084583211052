import React from 'react';
import { connect } from 'react-redux';
import { hideSessionLinks, showSessionLinks } from '../../../model/actions';
import LoginModal from '../../Modal/Login';
import RegistrationModal from '../../Modal/Registration';
import Spinner from '../../Spinner';
import './index.css';

class RequireLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginModal: true,
      showRegistrationModal: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(hideSessionLinks());
  }

  componentWillUnmount() {
    this.props.dispatch(showSessionLinks());
  }

  showLoginModal = () => {
    this.setState({
      showLoginModal: true,
      showRegistrationModal: false,
    });
  }

  showRegistrationModal = () => {
    this.setState({
      showLoginModal: false,
      showRegistrationModal: true,
    });
  }

  render() {
    return (
      <div className="RequireLoginPage">
        { this.props.pending && <Spinner/> }
        <LoginModal
          isOpen={!this.props.pending && this.state.showLoginModal}
          noOverlay
          shouldNotAnimate
          onNoAccount={this.showRegistrationModal}
          onRequestClose={() => {}}
        />
        <RegistrationModal
          isOpen={!this.props.pending && this.state.showRegistrationModal}
          noOverlay
          shouldNotAnimate
          onHaveAccount={this.showLoginModal}
          onRequestClose={() => {}}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pending: state.session.pending,
  };
};

export default connect(mapStateToProps)(RequireLoginPage);