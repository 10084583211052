import React from 'react';
import { connect } from 'react-redux';
import { getUserDetails } from '../../../util';
import './index.css';

function Section(props) {
  return (
    <div className="Section">
      {props.title && <div className="Title">{props.title}</div>}
      <div className="LineItems">
        {props.children}
      </div>
    </div>
  )
}

function LineItem(props) {
  return (
    <div className="LineItem">
      <div className="Label">{props.label}</div>
      <div className="Item">{props.children}</div>
    </div>
  );
}

class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
    };
  }

  render() {
    const { email, membershipType } = this.props;

    return (
      <div className="AccountPage Page">
        <Section title="Account">
          <LineItem label="Email">{email}</LineItem>
          <LineItem label="Membership Type">{membershipType}</LineItem>
        </Section>
        <Section title="Settings">
        </Section>
        <Section title="Stats">
        </Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { session } = state;
  const { user } = session;
  const { email, membershipType } = getUserDetails(user);
  return {
    email,
    membershipType,
  };
};
 
export default connect(mapStateToProps)(AccountPage);