import { 
  UPDATE_CONTENT, UPDATE_SESSION, SET_SESSION,
  ADD_NOTIFICATION, DISMISS_NOTIFICATION, NOTIFICATION_TYPES, 
  SET_PUZZLES, SET_SETTINGS, NOTIFICATION_DURATION_MS,
} from '../../constants';
import { defineApi } from '../api/generic';
import { ensureTrailingSlash } from '../../util';
import * as api from '../api';
import { 
  saveSessionInLocalStorage, 
  clearSessionInLocalStorage,
  saveSettingsInLocalStorage,
  clearSettingsInLocalStorage,
} from '../localStorage';

export const fetchContent = (url) => (dispatch) => {
  url = ensureTrailingSlash(url);
  const networkUrl = url[0] === '/' ? url.substring(1) : url;

  dispatch({
    type: UPDATE_CONTENT,
    payload: { [url]: { pending: true } },
  });

  api.getContent(networkUrl)
    .then(res => {
      const { data } = res;
      dispatch({
        type: UPDATE_CONTENT,
        payload: { [url]: data },
      });
    })
    .catch(error => {
      dispatch({
        type: UPDATE_CONTENT,
        payload: { [url]: { error } }
      });
    });
};

export const fetchPuzzles = () => (dispatch) => {
  api.getPuzzles()
    .then((res) => {
      dispatch({
        type: SET_PUZZLES,
        payload: res.data.results,
      });
    })
    .catch((e) => {
      // set generic network error state
    });
};

export const deletePuzzle = (id) => (dispatch) => {
  api.deletePuzzle(id)
    .then(res => {
      dispatch(fetchPuzzles());
    });
}

export const setToken = (token, keepMeLoggedIn) => (dispatch, getState) => {
  const session = getState().session;

  if (keepMeLoggedIn) {
    saveSessionInLocalStorage({ ...session, token });
  }

  dispatch({
    type: UPDATE_SESSION,
    payload: {
      token,
    },
  });
};

export const fetchCurrentUser = () => (dispatch) => {
  dispatch({
    type: UPDATE_SESSION,
    payload: {
      pending: true,
    },
  });
  api.me()
    .then(res => {
      dispatch({
        type: UPDATE_SESSION,
        payload: {
          user: res.data,
          pending: false,
        }
      });
    })
    .catch(_ => {
      dispatch(clearSession());
    });
};

let nextNotificationId = 1;
export const notify = (content, type=NOTIFICATION_TYPES.SUCCESS) => (dispatch) => {
  const id = nextNotificationId++;

  dispatch({
    type: ADD_NOTIFICATION,
    payload: { content, type, id, },
  });

  setTimeout(() => {
    dispatch({
      type: DISMISS_NOTIFICATION,
      payload: { id },
    });
  }, NOTIFICATION_DURATION_MS);
}

export const dismissNotification = (id) => {
  return {
    type: DISMISS_NOTIFICATION,
    payload: { id },
  }
}

export const promptLogin = () => {
  return {
    type: UPDATE_SESSION,
    payload: {
      promptLogin: true,
    },
  };
}

export const hideSessionLinks = () => {
  return {
    type: UPDATE_SESSION,
    payload: {
      hideSessionLinks: true,
    },
  };
}

export const showSessionLinks = () => {
  return {
    type: UPDATE_SESSION,
    payload: {
      hideSessionLinks: false,
    },
  };
}

export const loginWasPrompted = () => {
  return {
    type: UPDATE_SESSION,
    payload: {
      promptLogin: false,
    },
  };
}

export const clearSession = () => dispatch => {
  clearSessionInLocalStorage();
  dispatch({
    type: SET_SESSION,
    payload: {
      user: null,
      token: null,
      promptLogin: false,
    }
  });
}

export const updateSettings = (delta) => (dispatch, getState) => {
  clearSettingsInLocalStorage();
  const payload = { ...getState().settings, ...delta };
  dispatch({
    type: SET_SETTINGS,
    payload,
  });
  saveSettingsInLocalStorage(payload);
}

export const genericApi = defineApi({
  puzzles: {
    path: '/puzzles/',
    authorize: true,
  },
  articles: {
    path: '/articles/',
    authorize: true,
  },
});

console.log(genericApi);