import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import AutoForm from '../../Forms/Auto';
import AsyncButton from '../../Buttons/Async';
import GameViewer from '../../GameViewer';
import { genericApi } from '../../../model/actions';
import { TreeManager } from '../../../model/data/TreeManager';
import './index.css';
import * as api from '../../../model/api';
import DataMenu from '../../DataMenu';

const SCHEMA = {
  puzzles:  { 
    url: '/puzzles/', 
    schema: {
      name: '',
      fen: null,
      eco: null,
      archived: true,
    }, 
  },
  articles: { 
    url: '/articles/', 
    schema: {
      title: '',
      slug: '',
      version: 1,
      children: '',
      published: false,
    } 
  },
}

class EditorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource: null,
      treeManager: new TreeManager(),
      hasUnsavedChanges: false,
      treeManager: null,
      notFound: false,
    };
  }

  componentDidMount() {
    this.loadResource();
    this.loadResources();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params !== prevProps.match.params) {
      this.loadResource();
    }
  }

  loadResources() {
    const { type } = this.props.match.params;
    genericApi[type].list();
  }

  loadResource() {
    const { type, id } = this.props.match.params;
    const { url, schema } = SCHEMA[type];

    if (id === 'new') {
      this.createNewResource(schema);
      return;
    }

    api.getAuthorized(`${url}${id}/`)
      .then(res => {
        this.handleResource(res.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.setState({
            notFound: true,
            treeManager: null,
            resource: null,
          });
        }
      });
  }

  createNewResource(schema) {
    this.setState({
      resource: schema,
      treeManager: new TreeManager(),
    });
  }

  handleResource(resource, shouldRefresh=true) {
    try {
      this.setState({
        resource,
        notFound: false,
        treeManager: shouldRefresh ? TreeManager.load(resource) : this.state.treeManager,
      });
    } catch (err) {
      throw err;
    }
  }

  getBaseUrl() {
    const { type } = this.props.match.params;
    return `/editor/${type}/`;
  }

  getNextUrl() {
    const { id } = this.props.match.params;
    const nextId = parseInt(id, 10) + 1;
    return `${this.getBaseUrl()}${nextId}/`;
  }

  getPrevUrl() {
    const { id } = this.props.match.params;
    const prevId = parseInt(id, 10) - 1;
    return `${this.getBaseUrl()}${prevId}/`;
  }

  renderEditControls() {
    return (
      <div className="EditorControls">
        <Link to={this.getNextUrl()}>Next</Link>
        <Link to={this.getPrevUrl()}>Prev</Link>
        <AsyncButton
          className="Save"
          danger
          disabled={!this.state.hasUnsavedChanges}
          onClick={this.handleSave}
        >
          Save
        </AsyncButton>
      </div>
    )
  }

  handleTreeManagerChange = (treeManager) => {
    const { fen, children } = treeManager.serialize();
    this.setState({
      resource: { ...this.state.resource, fen, children },
      hasUnsavedChanges: true,
    });
  }

  handleSave = () => {
    const { type } = this.props.match.params;
    const { url } = SCHEMA[type];
    const data = this.state.resource;
    const path = data.id ? `${url}${data.id}/` : `${url}`;
    const method = data.id ? api.putAuthorized : api.postAuthorized;
    return method(path, data).then(res => {
      this.handleResource(res.data, false);
    });
  }

  handleSelectResource = (resource) => {
    const { type } = this.props.match.params;
    const { url } = SCHEMA[type];
    this.props.dispatch(push(`/editor${url}${resource.id}/`));
  }

  renderHeader() {
    const { type, id } = this.props.match.params;
    let text = `${type}/${id}`;
    if (this.state.notFound) {
      text = `${text} does not exist`;
    }
    return (
      <div className="Header">
        {text}
      </div>
    );
  }

  renderAutoForm() {
    return (
      <AutoForm 
        data={this.state.resource}
        exclude={['id', 'fen', 'children']}
        onChange={(data)=>{
          this.setState({ 
            resource: { ...this.state.resource, ...data },
            hasUnsavedChanges: true,
          });
        }}
      />
    );
  }

  render() {
    return (
      <div className="EditorPage">
        {this.renderHeader()}
        {this.state.treeManager && 
          <GameViewer
            title={this.state.resource.title}
            tree={this.state.treeManager.tree}
            editable
            kibitzer
            onChange={this.handleTreeManagerChange}
          />
        }
        { this.renderEditControls() }
        { this.renderAutoForm() }
        <DataMenu
          collapsible
          label={(item) => item.name || item.title}
          data={this.props.items}
          onLoadMore={this.loadMore}
          onSelect={this.handleSelectResource}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { type } = ownProps.match.params;
  const entry = state.api[type] || { results: [] };
  return {
    items: entry.results,
  };
};

export default connect(mapStateToProps)(EditorPage);